import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ExploreStock from "../../../../components/HTML/ExploreStock";
import PortfolioLayout from "../../../../components/Layout/Portfolio";
import StepComponent from "./stepComponent";
import style from './style.module.css';
import axios from "axios";
import { DMF_GET_MF_TRANSACTIONS_API_URL, IS_DIRECT } from "../../../../constants";
import {
    getUserId,
    getItemLocal,
    fetchEncryptData,
    indianRupeeFormat,
    fetchData,
} from "../../../../common_utilities";
import moment from "moment";

const TransactionInfo = (props) => {

    const [error, setError] = useState(false);
    const [mfTransactions, setMfTransactions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const transaction_id = useParams().transaction_id;
   
    const getMfTransactions = async (reset = false) => {
        try {            
            let payload = {
                url: DMF_GET_MF_TRANSACTIONS_API_URL,
                method: "post",
                data: {
                    user_id: "" + getUserId(),
                    transaction_id: transaction_id,
                    is_direct:IS_DIRECT

                },
            };

            let results = await fetchEncryptData(payload);
            
            if (results.error_code == 100 && Array.isArray(results?.data?.transaction_data)) {
                setMfTransactions(results.data.transaction_data);
            } else {
                if (results.error_code == 102) {
                    setMfTransactions([]);
                }
            }
        } catch (e) {
            console.error('err77', e);
            console.error("Something went wrong");
        }
    };


    useEffect(() => {
        getMfTransactions();
    }, []);

    const navigate = useNavigate();

    console.log('mfTransactions', mfTransactions);
    return (
        <>
            <PortfolioLayout>
                <div className={`${style.infoHeaderContainer}`}>
                    <span style={{ display: 'flex', alignItems: 'start', gap: '1rem' }}>
                        <div className="pointer" style={{ transform: 'rotate(180deg)' }} onClick={() => navigate(-1)}>
                            <ExploreStock />
                        </div>
                        <h3 >Order Summary </h3>
                    </span>
                </div>
                {mfTransactions.length > 0 &&
                    mfTransactions.map((val) => (
                    <div className={`${style.infoContainer}`}>
                        <div style={{ padding: '1rem' }}>
                            <div className={`${style.transactionInfoContainer}`}>
                                <div className="fundNameTd">
                                    <div className="fundName9">
                                        <img
                                        src={`${process.env.REACT_APP_STATIC_URL}/media/companyicons/${val.amc_code}.png`}
                                        onError={(e) => {
                                          e.target.src = `${process.env.PUBLIC_URL}/static/media/companyicons/amc_icon.png`;
                                          e.onError = null;
                                        }}
                                      />                                    
                                        <span className={`${style.transactionInfoContainerTitle}`}>{val.s_name}</span>
                                    </div>
                                </div>
                                <div className={`${style.transactionInfoContainerAmountContainer}`}> <span className={`${style.transactionInfoContainerAmount}`}>{indianRupeeFormat(val.cart_amount > 0 ? val.cart_amount * 1 : val.cart_units * val.nav)}</span></div>
                            </div>
                            <div className={`${style.transactionInfoDataContainer}`}>
                                <div className={`${style.transactionInfoDataItemLeft}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Transaction Type</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.cartpurchasetype}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemRight}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Transaction Date</div>
                                        <div className={`${style.transactionInfoDataItemLabelVal}`}>{moment(val.transaction_date).format(
                                            "DD-MM-YYYY"
                                        )}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemLeft}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Folio</div>
                                        <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.transaction_folio_no == 'new_folio' ? 'New Folio' : val.transaction_folio_no}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemRight}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Bank Account</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.bank_name}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemLeft}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>NAV</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{indianRupeeFormat(val.nav)}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemRight}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Units</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.units}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemLeft}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>NAV Date</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.nav_date === '-' ? (<span> - </span>) : (moment(val.nav_date, "YYYY-MM-DD").format("DD-MM-YYYY"))}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemRight}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Purchased by</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.name}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemLeft}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Order Amount</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{indianRupeeFormat(val.cart_amount > 0 ? val.cart_amount * 1 : val.cart_units * val.nav)}</div>
                                </div>
                                <div className={`${style.transactionInfoDataItemRight}`}>
                                    <div className={`${style.transactionInfoDataItemLabel}`}>Order Id</div>
                                    <div className={`${style.transactionInfoDataItemLabelVal}`}>{val.bse_reg_order_id}</div>
                                </div>
                                {val.cartpurchasetype === 'Sip' && (
                                    <>
                                    <div className={`${style.transactionInfoDataItemLeft}`}>
                                        <div className={`${style.transactionInfoDataItemLabel}`}>First SIP Date</div>
                                        <div className={`${style.transactionInfoDataItemLabelVal}`}>{moment(val.sip_start_date).format("DD-MM-YYYY")}</div>
                                    </div>
                                    <div className={style.transactionInfoDataItemRight}>
                                        <div className={style.transactionInfoDataItemLabel}>Mandate Id</div>
                                        <div className={style.transactionInfoDataItemLabelVal}>{val.transaction_bse_mandate_id}</div>
                                    </div>
                                    </>
                                )}
                                {val.cartpurchasetype === 'Lumpsum' && (
                                    <>
                                    <div className={style.transactionInfoDataItemLeft}>
                                        <div className={style.transactionInfoDataItemLabel}>Payment Mode</div>
                                        <div className={style.transactionInfoDataItemLabelVal}>{val.payment_mode}</div>
                                    </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={`${style.transactionInfoStepContainer}`}>
                            <div>
                                <StepComponent />
                            </div>
                        </div>
                    </div>
                ))}
                {mfTransactions.length > 0 && <div className={`${style.investmoreBtn}`}>
                    <Link to={`${process.env.PUBLIC_URL}/mutual-fund/MutualFund/${mfTransactions[0]["schemecode"]}`}>
                        <button>Invest More</button>
                    </Link>
                </div>}
            </PortfolioLayout>
        </>
    );
};

export default TransactionInfo;
