import { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PortfolioLayout from "../../../components/Layout/Portfolio";
import pmc from "../../../components/Layout/Portfolio/portfolio.module.css";
// import commonEncode from "../../../commonEncode";
import { FaLongArrowAltUp, FaDownload } from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { BsFillPrinterFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import iciciLogo from "../../../Assets/Images/01_icici.png";
import { ReactComponent as PiggybankIcon } from "../../../Assets/Images/piggybank.svg";
import { ReactComponent as DownloadIcon } from "../../../Assets/Images/download-reports.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Images/down-arr-98.svg";
import FintooDropdown from "../../../components/HTML/FintooDropdown";
import Investment from "../../../Assets/Images/portfolio/investment.png";
import Calender from "../../../Assets/Images/CommonDashboard/calendar-323.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchEncryptData,
  formatPrice,
  getUserId,
  indianRupeeFormat,
  loginRedirectGuest,
  getProfilePercentage,
  fetchUserData,
  getItemLocal,
  apiCall,
  fetchData,
  isUnderMaintenance,
  getPublicMediaURL,
  isFamilySelected,
  getParentUserId,
} from "../../../common_utilities";
import {
  DMF_GETUSERDETAILS_API_URL,
  GET_PORTFOLIO_SUMMARY_URL,
  DMF_PAR_REPORT_URL,
  DMF_CAPITAL_GAIN_REPORT_URL,
  SUPPORT_EMAIL,
  IS_DIRECT,
  DMF_SMALLCASE_MFLOAN_UNITYID,
  DMF_SMALLCASE_MFLOAN_INTERATCID,
} from "../../../constants";
import ExploreStock from "../../../components/HTML/ExploreStock";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as constClass from "../../../constants";
import FintooLoader from "../../../components/FintooLoader";
import style from "./style.module.css";
import Swal from "sweetalert2";
import ProgressStats from "./ProgressStats/ProgressStats";
import commonEncode from "../../../commonEncode";

import FilterSidePanel from "../components/filterSidePanel";
import {
  getSumOfDataListProp,
  calculatePercentage,
} from "../../../Utils/ListDataUtils/DataListOperations";
import { HiSortAscending } from "react-icons/hi";
import MainLayout from "../../../components/Layout/MainLayout";
import Modal from "react-responsive-modal";
import { Modal as ReactModal } from "react-responsive-modal";
import SelectMemberModal from "../../../components/SelectMemberModal";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FintooInlineLoader from "../../../components/FintooInlineLoader";

const AssetName = ({ title, icon }) => {
  return (
    <div className={`d-flex ${style.flexBxAssetName}`}>
      <div className="pe-3">
        <img
          class={`d-none d-md-block ${style.tblIcons}`}
          src={
            icon ??
            process.env.REACT_APP_STATIC_URL_PYTHON +
              "/assets/img/insurance/insurance_insurance_form.svg"
          }
        />
      </div>
      <div>{title}</div>
    </div>
  );
};
const PortfolioDashboard = (props) => {
  const successMessage = [
    "Fd_bond data deleted successfully.",
    "Po data deleted successfully.",
    "Real_estate data deleted successfully.",
    "Insurance data deleted successfully.",
    "Liquid data deleted successfully.",
  ];
  const errorMessage = [
    "No active Fd_bond data found!",
    "No active Po data found!",
    "No active Real_estate data found!",
    "No active Liquid data found!",
    "Please Provide Valid inv_type",
  ];

  const repdata = {
    fileP: "",
  };
  const [returnsType, setReturnsType] = useState({
    header: "xirr",
    insideTable: "xirr",
  });
  const [selectedTab, setSelectedTab] = useState(1);
  const [mainData, setMainData] = useState([]);
  const [mfListDataCopy, setMfListDataCopy] = useState([]);
  const [text, setpopuptext] = useState("");
  const [Open, setIsOpen] = useState(false);
  const [userExternalFundData, setUserExternalFundData] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const openModal1 = () => {
    setIsOpen1(true);
  };
  const closeModal1 = () => {
    setIsOpen1(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const [resetFilterTriggerState, setResetFilterTriggerState] = useState({
    triggerReset: false,
    showResetTriggerUi: false,
    filtersActive: false,
  });
  const [progressBarValues, setProgressBarValues] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(repdata);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useRef({});
  const btnDownloadRef = useRef();
  const cgDownloadRef = useRef();
  // const [isDataLoading, setIsDataLoading] = useState(true);
  console.log("maindata", mainData);
  const [isDataLoading, setIsDataLoading] = useState({
    dashboardData: true,
    mfData: true,
    otherInvestmentData: true,
  });
  const [otherInvestmentData, setOtherInvestmentData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFilterPanelActive, setIsFilterPanelActive] = useState(false);

  useEffect(() => {
    if (searchParams.get("realestate") == 1) {
      searchParams.delete("realestate");
      setSearchParams(searchParams);
      setSelectedTab(5);
    }
    if (searchParams.get("fdbonds") == 3) {
      searchParams.delete("fdbonds");
      setSearchParams(searchParams);
      setSelectedTab(3);
    }
    if (searchParams.get("liquidasset") == 1) {
      searchParams.delete("liquidasset");
      setSearchParams(searchParams);
      setSelectedTab(8);
    }
    if (searchParams.get("insurance") == 2) {
      searchParams.delete("insurance");
      setSearchParams(searchParams);
      setSelectedTab(2);
    }
    // if (searchParams.get("po") == 4) {
    //   searchParams.delete("po");
    //   setSearchParams(searchParams);
    //   setSelectedTab(4);
    // }
    if (isUnderMaintenance()) {
      Swal.fire({
        html: "" + isUnderMaintenance(true)["string"] + "",
      });
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
  }, [getItemLocal("family") && getItemLocal("member").length === 0]);

  useEffect(() => {
    fetchFundsData();
    fetchInsuranceData();
    getDashboardData();
  }, [getItemLocal("family") && getItemLocal("member").length !== 0]);

  useEffect(() => {
    if (searchParams.get("assetTabNumber")) {
      setSelectedTab(searchParams.get("assetTabNumber"));
    }
  }, [searchParams]);

  useEffect(() => {
    fetchFundsData();
    fetchInsuranceData();
    userProfileState();
    getDashboardData();
    getFamilyMembers();
    // document.body.classList.remove("bgImagClass");
  }, []);

  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: IS_DIRECT,
      };

      let member_data = await apiCall(
        constClass.GET_MEMBER_LIST,
        data,
        true,
        false
      );

      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({
              member_id: member.id,
              value: member.fp_user_id,
              label: "Self",
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent,
              pan: member.pan,
            });
          } else {
            member_array.push({
              member_id: member.id,
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent,
              pan: member.pan,
            });
          }
        });

        setFamilyData(member_array);
      } else {
        setFamilyData([]);
      }
    } catch {}
  };

  const getDashboardData = async () => {
    // setIsDataLoading(true);
    setIsDataLoading((prev) => ({ ...prev, dashboardData: true }));
    let data = {};
    if (getItemLocal("family")) {
      var new_array_ids = [];
      var new_array_pans = [];
      var new_data = getItemLocal("member");
      new_data.forEach((element) => {
        if (element.id !== null) {
          new_array_ids.push(element.id.toString());
        }
        if (element.pan !== null) {
          new_array_pans.push(element.pan);
        }
      });
      data = {
        user_id: new_array_ids,
        pan: new_array_pans,
        is_direct: IS_DIRECT,
      };
    } else {
      data = { user_id: getUserId(), is_direct: IS_DIRECT };
    }
    var res = {};
    if (localStorage.getItem("dashboardData")) {
      res = JSON.parse(localStorage.getItem("dashboardData"));
    } else {
      var payload = {
        url: constClass.DMF_GET_DASHBOARD_DATA,
        data: data,
        method: "post",
      };
      var res = await fetchEncryptData(payload);
      if (res.error_code == "100") {
        localStorage.setItem("dashboardData", JSON.stringify(res));
      }
    }

    // setIsDataLoading(false);
    setIsDataLoading((prev) => ({ ...prev, dashboardData: false }));
    if (res.error_code == "100") {
      setDashboardData(res.data);
      // GraphData(res.data);
    }
  };

  const deleteInsuranceItem = async (inv_id, type, fplid) => {
    try {
      setIsLoading(true);
      let payload = {
        url: constClass.DELETE_OTHER_INVESTMENTS,
        data: {
          user_id: getUserId(),
          inv_type: type,
          inv_id: inv_id.toString(),
          is_direct: IS_DIRECT,
        },
        method: "post",
      };
      let res = await fetchEncryptData(payload);
      setIsLoading(false);
      if (successMessage.indexOf(res.message) > -1) {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: res.message, type: "success" },
        });
        localStorage.removeItem("dashboardData");
        localStorage.removeItem("MFSummaryPortfolio");
        fetchInsuranceData();
        getDashboardData();
        return;
      } else if (errorMessage.indexOf(res.message) > -1) {
        setIsLoading(true);
        let payload1 = {
          url: constClass.DELETE_FP_USER_ASSET,
          data: {
            user_id: getUserId(),
            id: inv_id.toString(),
            fp_log_id: fplid,
          },
          method: "post",
        };
        let res2 = await fetchEncryptData(payload1);
        setIsLoading(false);
        if (res2.error_code == "100") {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Data deleted successfully!",
              type: "success",
            },
          });
          localStorage.removeItem("dashboardData");
          localStorage.removeItem("MFSummaryPortfolio");
          fetchInsuranceData();
          getDashboardData();
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Error!", type: "error" },
          });
        }
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong. Try again later.",
            type: "error",
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchInsuranceData = async () => {
    try {
      if (getItemLocal("family")) {
        var new_array = [];
        var new_data = getItemLocal("member");
        new_data.forEach((element) => {
          if (element.id !== null) {
            new_array.push(element.id.toString());
          }
        });
      }
      setIsLoading(true);
      // setIsDataLoading(true);
      setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: true }));

      var res = {};
      if (localStorage.getItem("MFSummaryPortfolio")) {
        res = JSON.parse(localStorage.getItem("MFSummaryPortfolio"));
      } else {
        var payload = {
          url: constClass.GET_OTHER_INVESTMENTS,
          data: {
            user_id: getItemLocal("family") ? new_array : getUserId(),
            inv_type: "all",
            is_direct: IS_DIRECT,
          },
          method: "post",
        };
        res = await fetchEncryptData(payload);
        if (res.error_code == "100") {
          localStorage.setItem("MFSummaryPortfolio", JSON.stringify(res));
        }
      }

      // setIsDataLoading(false);
      setIsLoading(false);
      if (res.error_code == "100") {
        // console.log("mmmmmmmmmmmmmmmmmmm", res.data);
        setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: false }));
        setOtherInvestmentData(res.data);
      } else {
        setOtherInvestmentData([]);
      }
    } catch (e) {
      // setIsDataLoading(false);
      setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: false }));
      setIsLoading(false);
      console.error(e);
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(value);

  const fetchFundsData = async () => {
    console.log("user", userDetails);
    try {
      if (getItemLocal("family")) {
        var new_array = [];
        var new_data = getItemLocal("member");
        new_data.forEach((element) => {
          if (element.pan !== null) {
            new_array.push(element.pan);
          }
        });
      }
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }

      var res = {};
      if (localStorage.getItem("UserPANInfo")) {
        res = JSON.parse(localStorage.getItem("UserPANInfo"));
      } else {
        var payload = {
          url: DMF_GETUSERDETAILS_API_URL,
          method: "post",
          data: { user_id: "" + getUserId(), is_direct: IS_DIRECT },
        };

        var res = await fetchEncryptData(payload);
        // if (Boolean(res.data.pan) == false) {
        //   throw "PAN not found";
        // }
        if (res.data.is_minor == "N") {
          if (Boolean(res.data.pan) == false) {
            throw "PAN not found";
          }
        } else if (res.data.is_minor == "Y") {
          if (Boolean(res.data.guardian_pan) == false) {
            throw "PAN not found";
          }
        }
        localStorage.setItem("UserPANInfo", JSON.stringify(res));
      }

      userDetails.current = res.data;

      let storageName =
        "MFFunds_" + Number(isFamilySelected()) + "_" + getUserId();
      if (localStorage.getItem(storageName)) {
        res = JSON.parse(localStorage.getItem(storageName));
      } else {
        var payload = {
          url: GET_PORTFOLIO_SUMMARY_URL,
          data: {
            // pan: "AUFPV0822N",
            pan: getItemLocal("family") ? new_array : res.data.pan,
            is_direct: IS_DIRECT,
          },
          method: "post",
        };
        res = await fetchEncryptData(payload);
        if (res.error_code == "100") {
          localStorage.setItem(storageName, JSON.stringify(res));
        }
      }

      setIsDataLoading((prev) => ({ ...prev, mfData: true }));
      if (res.error_code == "100") {
        console.log("mainnnndata", res.data);
        setMainData(res.data);
        setMfListDataCopy(res.data.fund_details);
        GraphData(res.data);
        setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      } else {
        setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      }
    } catch (e) {
      setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      console.error(e);
    }
  };

  const GraphData = (data) => {
    // try {
    //   let graph_data = [];
    //   Object.keys(data?.investment?.inv_data).forEach((key) => {
    //     const percValue = Math.round(
    //       (data?.investment?.inv_data[key]?.perc * 100) / 100
    //     );
    //     graph_data.push({
    //       title: key,
    //       value: percValue,
    //       color: "",
    //     });
    //   });
    //   setProgressBarValues(graph_data);
    // } catch (e) {
    //   console.error("dashboard error ---->", e);
    // }

    let graph_data = [];
    let barcolor = "";
    if (data.graph_data["Hybrid"]) {
      data.graph_data["Debt"] = data.graph_data["Debt"]
        ? data.graph_data?.["Debt"] + data.graph_data?.["Hybrid"]
        : data.graph_data?.["Hybrid"];
      delete data.graph_data["Hybrid"];
    }
    Object.keys(data.graph_data).forEach((key) => {
      if (key == "Equity") {
        barcolor = "#042b62";
      }
      if (key == "Debt") {
        barcolor = "#ff8c00";
      }

      graph_data.push({
        title: key,
        value: parseFloat(
          (data.graph_data[key] / data.tcurr_value) * 100
        ).toFixed(2),
        color: barcolor,
      });
    });
    console.log("graph_data", graph_data);
    setProgressBarValues(graph_data);
  };

  const userProfileState = async () => {
    // setIsLoading1(true);
    try {
      const userdata = await fetchUserData(true);
      const p = await getProfilePercentage(userdata);
      setPercent(p);
    } catch (e) {
      // console.log("natu err");
      // console.error("natu err", e);
    }
  };

  useEffect(() => {
    if (file.fileP) {
      document.querySelector("#pa-download").click();
      // setFileP("");
    }
  }, [file.fileP]);

  const parApi = async () => {
    const parRep = {
      user_id: "" + getUserId(),
      pan: userDetails.current.pan,
    };
    var payload_par = {
      url: DMF_PAR_REPORT_URL,
      method: "POST",
      data: parRep,
      headers: {
        gatewayauthtoken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJnYXRld2F5bmFtZSI6Imh0dHBzOi8vc3RnLm1pbnR5LmNvLmluLyIsImV4cCI6MTY4ODM4MjU2OX0.x2_gelbtpUBq6sSVajq-nhBwM7COXgnuaPir-IQyIRM",
        "Content-Type": "text/plain",
      },
    };
    var resp = await fetchEncryptData(payload_par);
    // setParApiresp(resp)
    return resp;
  };

  const parData = async () => {
    try {
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }
      if (file.fileP) {
        document.querySelector("#pa-download").click();
        return;
      }
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please Wait...",
          type: "info",
        },
      });
      btnDownloadRef.current.setAttribute("disabled", true);
      isLoading(true);
      let resp = await parApi();
      isLoading(false);
      if (resp.error_code == "100") {
        const response = await fetch(resp.pdf_report_link);
        const blob = await response.blob();
        // setFile.fileP(URL.createObjectURL(blob));
        setFile((prev) => ({ ...prev, fileP: URL.createObjectURL(blob) }));
        btnDownloadRef.current.removeAttribute("disabled");
      } else if (resp.error_code == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "No records found!",
            type: "info",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong...",
            type: "error",
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMailPar = async () => {
    isLoading(true);
    let resp = await parApi();
    if (resp.pdf_report_link) {
      var urlmail = {
        userdata: {
          to: userDetails.current.email,
        },
        subject: "Fintoo - Par Report",
        template: "transactions_dmf.html",
        contextvar: {
          name: userDetails.current.name,
          SUPPORT_EMAIL: SUPPORT_EMAIL,
          report_link: resp.pdf_report_link,
        },
      };

      // var data = commonEncode.encrypt(JSON.stringify(urlmail));
      let config = {
        method: "post",
        url: constClass.DMF_SENDMAIL_API_URL,
        data: urlmail,
      };

      var res = await fetchEncryptData(config);
      isLoading(false);
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: res.message, type: "success" },
      });
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Email not sent, Something went wrong...",
          type: "error",
        },
      });
    }
  };

  const detailsPage = (v) => {
    var detailsData = JSON.stringify({
      pan: v.pan,
      is_direct: IS_DIRECT,
      fund_registrar: v.fund_registrar,
      prod_code: v.prod_code,
      folio_no: v.folio_no,
      amc_code: v.amc_code,
    });

    var name_user = v.name.toString();

    localStorage.setItem("detailsData", detailsData);
    // added for temporary name change
    dispatch({ type: "SET_TEMP_NAME", payload: name_user });
    navigate(process.env.PUBLIC_URL + "/mutual-fund/portfolio/dashboard/fund");
  };

  const checkIfPanExists = async () => {
    console.log("hello", userDetails);
    try {
      var reqData = {
        method: "post",
        url: constClass.DMF_CHECKIFPANEXISTS_API_URL,
        data: {
          pan: userDetails.current.pan,
          fp_user_id: getUserId(),
        },
      };
      let checkPan = await fetchData(reqData);
      if (checkPan.error_code == "100") {
        navigate(
          process.env.PUBLIC_URL + "/mutual-fund/portfolio/link-your-holdings"
        );
      } else if (checkPan.error_code == "101") {
        setpopuptext(
          "Please complete your profile to access all features and enjoy a tailored experience"
        );
        openModal();
        // dispatch({
        //   type: "RENDER_TOAST",
        //   payload: { message: checkPan.message, type: "error" },
        // });
      } else if (checkPan.error_code == "102") {
        setpopuptext(
          "Please complete your profile to access all features and enjoy a tailored experience"
        );
        openModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = () => {
    navigate(process.env.PUBLIC_URL + "/mutual-fund/profile/");
    // if (status === "N" && profilepercent == 100) {
    //   console.log("!=100")
    //   navigate(process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard/nominee")
    // }
  };

  const checkHoldingStatus = async () => {
    try {
      const payload = {
        method: "post",
        url: constClass.RMF_GET_SC_CHECK_STATUS,
        data: {
          pan: userDetails.current.pan,
          is_direct: constClass.IS_DIRECT,
        },
      };
      const res = await fetchData(payload);
      console.log("res---->", res);
      if (res.error_code == 100) {
        setUserExternalFundData(res.data[0] ?? {});
      } else {
        throw "";
      }
    } catch (e) {
      console.error("res---->", e);
    }
  };

  useEffect(() => {
    if (userDetails?.current?.pan) {
      checkHoldingStatus();
    }
  }, [userDetails?.current?.pan]);

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const smallcasemfloan = async () => {
    var payload = {
      url: DMF_GETUSERDETAILS_API_URL,
      method: "post",
      data: { user_id: "" + getUserId(), is_direct: IS_DIRECT },
    };

    var res = await fetchEncryptData(payload);
    console.log(IS_DIRECT, getUserId());
    userDetails.current = res.data;

    var data = {
      pan: userDetails.current.pan,
      contact: userDetails.current.mobile,
    };

    var res = await apiCall(DMF_SMALLCASE_MFLOAN_UNITYID, data);
    var unity_id = res.data.data.unityUserId;

    if (unity_id) {
      var data = {
        userid: unity_id,
      };
      var tokenres = await apiCall(DMF_SMALLCASE_MFLOAN_INTERATCID, data);
      console.log("token response", tokenres.data);
      var interaction_id = tokenres.data;
      console.log("interaction_id", interaction_id);

      if (interaction_id) {
        const lasSdkInstance = new window.ScLoan({
          // ! gateway name integration key is shared by business team
          gatewayName: "fintoo",
        });

        try {
          const response = await lasSdkInstance.apply({
            interactionToken: interaction_id,
          });
          console.log("loan response", response);
          // handle success response
        } catch (e) {
          console.log("else");
          // handle error
        }
      }
    } else {
      console.log("api response failed");
    }
  };

  const [familyData, setFamilyData] = useState([]);
  const [openAllocation, setOpenAllocation] = useState(false);
  const [allocationLoading, setAllocationLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br/>{point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        innerSize: "0%",
        name: "",
        colorByPoint: true,
        data: [],
      },
    ],
  });

  const fetchAllocation = async () => {
    try {
      setOpenAllocation(true);
      setAllocationLoading(true);
      console.log("e44", familyData, isFamilySelected(), getUserId());
      let memberList = [];
      if (isFamilySelected()) {
        memberList = familyData.filter((v) => v?.member_id);
      } else {
        memberList = familyData.filter(
          (v) => v?.member_id && v?.member_id == getUserId()
        );
      }
      console.log("qwe2", memberList);
      let r = await fetchData({
        method: "post",
        url: constClass.DMF_GET_ASSETALLOCATION,
        data: {
          user_id: memberList.map((v) => "" + v.member_id),
          pan: memberList.filter((v) => v?.pan).map((v) => "" + v.pan),
          is_direct: IS_DIRECT,
          fp_user_details_id: isFamilySelected() ? 0 : memberList[0].value,
        },
      });
      if (r.error_code != 100) {
        throw new Error(r.message);
      }
      console.log("r33-->", r.data);
      setGraphData(r.data);
      let _graphdata = r.data.map((v) => {
        return { name: v.title, y: v.value };
      });
      setChartOptions((prev) => ({
        ...prev,
        series: { ...prev.series, data: _graphdata },
      }));
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: e.message, type: "error" },
      });
      setOpenAllocation(false);
    } finally {
      setAllocationLoading(false);
    }
  };
  console.log("graph666", chartOptions);

  const memebermodal = async (type = null) => {
    if (getItemLocal("family") == "1") {
      setpopuptext("Please select member from the dropdown to proceed.");
      openModal1();
    }
  };

  return (
    <MainLayout>
      <PortfolioLayout>
        <ReactModal
          classNames={{
            modal: "ModalpopupContentWidth",
          }}
          open={isOpen1}
          showCloseIcon={false}
          center
          animationDuration={0}
          closeOnOverlayClick={false}
          large
        >
          <div className="text-center">
            <h3 className="HeaderText">Attention !</h3>
            <div className="">
              <div
                className="PopupImg"
                style={{ width: "40%", margin: "0 auto" }}
              >
                <img
                  style={{ width: "100%" }}
                  src={getPublicMediaURL("/static/media/RMF/SelectingTeam.svg")}
                ></img>
              </div>
              <div className="p-2">
                <p
                  className="PopupContent"
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "normal",
                    padding: "0 1rem",
                    width: "90%",
                    margin: "0 auto",
                  }}
                >
                  Please select member from the dropdown to proceed.
                </p>
              </div>
              <div
                className="ButtonBx aadharPopUpFooter"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  className="ReNew"
                  onClick={() => {
                    closeModal1();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        <Modal
          classNames={{
            modal: "ModalpopupContentWidth",
          }}
          open={Open}
          showCloseIcon={false}
          center
          animationDuration={0}
          closeOnOverlayClick={false}
          large
        >
          <div className="text-center">
            <h3 className="HeaderText">Attention !</h3>
            <div className="">
              <div
                className="PopupImg"
                style={{ width: "40%", margin: "0 auto" }}
              >
                <img
                  style={{ width: "100%" }}
                  src={getPublicMediaURL("/static/media/RMF/SelectingTeam.svg")}
                />
              </div>
              <div className="p-2">
                <p
                  className="PopupContent"
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "normal",
                    padding: "0 1rem",
                    width: "90%",
                    margin: "0 auto",
                  }}
                >
                  {text}
                </p>
              </div>
              <div
                className="ButtonBx aadharPopUpFooter"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  className="ReNew"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          classNames={{
            modal: "ModalpopupContentWidth",
          }}
          open={openAllocation}
          center
          animationDuration={0}
          large
          closeOnOverlayClick={true}
          onClose={() => setOpenAllocation(false)}
          showCloseIcon={true}
        >
          <div className="text-center">
            <h3>Portfolio Asset Allocation</h3>
            {/* ${allocationLoading ? "invisible" : "visible"}  */}
            <div className={`position-relative`}>
              {allocationLoading && (
                <div
                  className="position-absolute w-100"
                  style={{
                    zIndex: 100,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FintooInlineLoader isLoading={true} />
                </div>
              )}
              <div className={`${allocationLoading ? "invisible" : "visible"}`}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
              <div
                className="text-start"
                style={{
                  backgroundColor: "#ecf9e4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {graphData.map(
                  (v) =>
                    v?.breakdown?.length > 0 && (
                      <div key={v.title}>
                        <h4 style={{ textTransform: "capitalize" }}>
                          {v.title}
                        </h4>
                        <div>
                          {v.breakdown.map((_v, index) => (
                            <div key={index} className="allocation-badge me-3">
                              {_v.title}:&nbsp;
                              <span>{Number(_v.value).toFixed(2)}%</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </Modal>

        <FintooLoader isLoading={isLoading} />
        {!isLoading && (
          <>
            <>
              {/* <p style={{ height: "2rem" }}></p> */}
              <div className="row">
                <div className="col-12">
                  <div className="mybox mt-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-none d-md-flex justify-content-between">
                          <div></div>
                          <div className="topOptions">
                            {getItemLocal("family") ? (
                              <div
                                className="topOptionsButton pointer disabled"
                                onClick={() =>
                                  navigate(
                                    process.env.PUBLIC_URL +
                                      "/mutual-fund/funds/all"
                                  )
                                }
                              >
                                <PiggybankIcon width={"17px"} height={"17px"} />
                                <span>Add Funds</span>
                              </div>
                            ) : (
                              <div
                                className="topOptionsButton pointer"
                                onClick={() =>
                                  navigate(
                                    process.env.PUBLIC_URL +
                                      "/mutual-fund/funds/all"
                                  )
                                }
                              >
                                <PiggybankIcon width={"17px"} height={"17px"} />
                                <span>Add Funds</span>
                              </div>
                            )}

                            {getItemLocal("family") ? (
                              <div
                                className="topOptionsButton pointer"
                                onClick={async () => memebermodal()}
                              >
                                <PiggybankIcon width={"17px"} height={"17px"} />
                                <span>MF Loan</span>
                              </div>
                            ) : (
                              <div
                                className="topOptionsButton pointer"
                                onClick={() => smallcasemfloan()}
                              >
                                <PiggybankIcon width={"17px"} height={"17px"} />
                                <span>MF Loan</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="myboxInner">
                          {/* {isDataLoading == true && ( */}
                          {/* {!progressBarValues && !dashboardData?.investment && (
                            <div className="row">
                              <div
                                className="shine"
                                style={{ width: "100%", height: "2rem" }}
                              ></div>
                            </div>
                          )} */}
                          {/* {isDataLoading == false && ( */}
                          {!isDataLoading.dashboardData ? (
                            <div className={`${style.statsContainer} row`}>
                              <div className={`${style.portfolioValue} col-4`}>
                                <p className={`smallCalendar mb-0 pt-1`}></p>
                                <p className="mb-0 pb-2">
                                  <strong>Your Portfolio Value</strong>
                                </p>
                                <div className="valueBox pointer">
                                  <p className="valueBoxPortolio pe-2">
                                    <>
                                      <span className="valueBoxSymbol">₹</span>
                                      <span className="valueBoxAmount">
                                        {dashboardData?.investment &&
                                        "t_curr_val" in dashboardData.investment
                                          ? numberFormat(
                                              dashboardData.investment
                                                .t_curr_val * 1
                                            )
                                          : 0}
                                      </span>
                                    </>
                                  </p>
                                  {Number(
                                    dashboardData?.investment?.t_curr_val || 0
                                  ) != 0 && (
                                    <img
                                      onClick={() => {
                                        fetchAllocation();
                                      }}
                                      src={getPublicMediaURL(
                                        "/static/media/icons/pie-chart-ic.png"
                                      )}
                                      width={30}
                                      alt={"Asset Allocation Graph"}
                                      title={"Asset Allocation Graph"}
                                    />
                                  )}
                                </div>

                                <div>
                                  <p className="small-para mb-0 pt-2">
                                    <span
                                      className={`pointer ${
                                        getItemLocal("family") ? "enable" : ""
                                      }`}
                                      onClick={() => {
                                        navigate(
                                          process.env.PUBLIC_URL +
                                            "/mutual-fund/portfolio/link-your-holdings"
                                        );
                                      }}
                                    >
                                      <i class="fa-solid fa-rotate"></i>
                                      &nbsp;&nbsp;
                                    </span>
                                    {Boolean(
                                      userExternalFundData?.Updated_Datetime
                                    ) &&
                                      "Last Updated on " +
                                        moment(
                                          userExternalFundData.Updated_Datetime
                                        ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className={`${style.statsData} col-8`}>
                                <ProgressStats
                                  selectedTab={selectedTab}
                                  data={progressBarValues}
                                  onSelect={(_number) => {
                                    setSelectedTab(_number);
                                    setSearchParams({
                                      ["assetTabNumber"]: _number,
                                    });
                                    console.log("nil77", _number);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div
                                className="shine"
                                style={{ width: "100%", height: "2rem" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p style={{ height: "2rem" }}></p>
              <div className="row">
                <div className="col-12">
                  <div className="col-12">
                    <div
                      className={`insideTabContent tbl-01 px-2 px-md-4 ${
                        selectedTab == 1 ? "" : "d-none"
                      }`}
                    >
                      {isDataLoading.mfData ? (
                        <Table
                          responsive
                          className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <div>
                          {Boolean(
                            "fund_details" in mainData &&
                              mainData.fund_details.length
                          ) && (
                            <div className="row pb-3">
                              <div className="col-12 col-md-9">
                                <div className="d-none d-md-block">
                                  <div className="cntResults">
                                    <div className="cntRItems">
                                      <div className="borderSpace">
                                        Total Funds
                                      </div>
                                      <div
                                        className={`borderSpace borderText pointer`}
                                      >
                                        {/* {"tfunds" in mainData && mainData.tfunds}{" "} */}
                                        {"tfunds" in mainData &&
                                          mainData.fund_details.length}{" "}
                                      </div>
                                    </div>
                                    <div className="cntRItems">
                                      <div className="borderSpace">
                                        Active SIP
                                      </div>
                                      <div
                                        className={`borderSpace borderText pointer`}
                                      >
                                        {mainData?.active_sips}
                                        <span style={{ fontSize: ".9rem" }}>
                                          &nbsp;(
                                          {indianRupeeFormat(
                                            mainData?.active_sip_amount
                                          )}
                                          )
                                        </span>
                                      </div>
                                    </div>
                                    <div className="cntRItems">
                                      <div className="borderSpace">
                                        Invested
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {"tinvested_value" in mainData &&
                                        !resetFilterTriggerState.filtersActive
                                          ? indianRupeeFormat(
                                              mainData.tinvested_value
                                            )
                                          : indianRupeeFormat(
                                              getSumOfDataListProp(
                                                mainData.fund_details,
                                                "inv"
                                              )
                                            )}
                                      </div>
                                    </div>
                                    <div className="cntRItems">
                                      <div className="borderSpace">
                                        Total Fund Value
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {"tcurr_value" in mainData &&
                                        !resetFilterTriggerState.filtersActive
                                          ? indianRupeeFormat(
                                              mainData.tcurr_value
                                            )
                                          : indianRupeeFormat(
                                              getSumOfDataListProp(
                                                mainData.fund_details,
                                                "curr_val"
                                              )
                                            )}
                                      </div>
                                    </div>

                                    <div className="cntRItems">
                                      <div className="borderSpace">Returns</div>
                                      <div className={`borderSpace borderText`}>
                                        {"tgain_loss" in mainData &&
                                        !resetFilterTriggerState.filtersActive
                                          ? indianRupeeFormat(
                                              mainData.tgain_loss
                                            )
                                          : indianRupeeFormat(
                                              getSumOfDataListProp(
                                                mainData.fund_details,
                                                "gain_loss"
                                              )
                                            )}
                                      </div>
                                    </div>

                                    <div className="cntRItems">
                                      <div className="borderSpace">
                                        Day Change
                                      </div>
                                      <div
                                        className={`borderSpace borderText d-flex`}
                                      >
                                        <span
                                          className={` xrr-returns ${
                                            mainData.tone_day_return < 0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          {"tone_day_return" in mainData &&
                                          !resetFilterTriggerState.filtersActive
                                            ? indianRupeeFormat(
                                                mainData.tone_day_return
                                              )
                                            : indianRupeeFormat(
                                                getSumOfDataListProp(
                                                  mainData.fund_details,
                                                  "day_change"
                                                )
                                              )}
                                          &nbsp;
                                        </span>

                                        <small
                                          className={`valueBoxPercentage xrr-returns ${
                                            mainData.tone_day_return_percentage <
                                            0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          (
                                          {"tone_day_return_percentage" in
                                            mainData &&
                                          !resetFilterTriggerState.filtersActive
                                            ? mainData.tone_day_return_percentage
                                            : calculatePercentage(
                                                getSumOfDataListProp(
                                                  mainData.fund_details,
                                                  "curr_val"
                                                ),
                                                mainData.tone_day_prev_net_val
                                              )}
                                          )&nbsp;
                                          {/* ({mainData.tone_day_return_percentage}%)&nbsp; */}
                                          <FaLongArrowAltUp />
                                        </small>
                                      </div>
                                    </div>
                                    <div
                                      className="cntRItems pointer"
                                      onClick={() =>
                                        setReturnsType((prev) => ({
                                          ...prev,
                                          header:
                                            prev.header == "xirr"
                                              ? "absolute"
                                              : "xirr",
                                        }))
                                      }
                                    >
                                      <div className="borderSpace triangle-ct">
                                        {returnsType.header == "xirr"
                                          ? "XIRR"
                                          : "Absolute"}
                                        &nbsp;%
                                      </div>
                                      {"tabs_return_percentage" in mainData &&
                                        "txirr_percentage" in mainData && (
                                          <div
                                            className={`borderSpace borderText `}
                                          >
                                            {returnsType.header == "xirr" && (
                                              <p
                                                className={`valueBoxPercentage ${
                                                  mainData.txirr_percentage *
                                                    1 <
                                                  0
                                                    ? "red"
                                                    : "green"
                                                }`}
                                              >
                                                <span>
                                                  {mainData.txirr_percentage > 0
                                                    ? +mainData.txirr_percentage
                                                    : mainData.txirr_percentage}
                                                  %
                                                </span>
                                                <FaLongArrowAltUp />
                                              </p>
                                            )}
                                            {returnsType.header ==
                                              "absolute" && (
                                              <p
                                                className={`valueBoxPercentage ${
                                                  mainData.tabs_return_percentage *
                                                    1 <
                                                  0
                                                    ? "red"
                                                    : "green"
                                                }`}
                                              >
                                                <span>
                                                  {mainData.tabs_return_percentage >
                                                  0
                                                    ? +mainData.tabs_return_percentage
                                                    : mainData.tabs_return_percentage}
                                                  %
                                                </span>
                                                <FaLongArrowAltUp />
                                              </p>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-block d-md-none ewewed">
                                <div
                                  className="row"
                                  style={{
                                    border: "1px solid #d8d8d8",
                                  }}
                                >
                                  <div className="col-6 py-2">
                                    <div>Total Funds</div>
                                    <div className="text-bold">
                                      {mainData?.fund_details.length ?? ""}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Total Fund Value</div>
                                    <div className="text-bold">
                                      {"tcurr_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tcurr_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "curr_val"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Invested</div>
                                    <div className="text-bold">
                                      {"tinvested_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tinvested_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "inv"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Returns</div>
                                    <div className="text-bold">
                                      {"tgain_loss" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(mainData.tgain_loss)
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "gain_loss"
                                            )
                                          )}
                                    </div>
                                  </div>

                                  <div
                                    className="col-6 py-2"
                                    onClick={() =>
                                      setReturnsType((prev) => ({
                                        ...prev,
                                        header:
                                          prev.header == "xirr"
                                            ? "absolute"
                                            : "xirr",
                                      }))
                                    }
                                  >
                                    <div className="borderSpace align-items-center d-flex">
                                      <div className="pe-2 returns-txt">
                                        {returnsType.header == "xirr"
                                          ? "XIRR"
                                          : "Absolute"}
                                        &nbsp;%
                                      </div>
                                      {"tabs_return_percentage" in mainData &&
                                        "txirr_percentage" in mainData && (
                                          <div
                                            className={`borderSpace borderText `}
                                          >
                                            {returnsType.header == "xirr" && (
                                              <p
                                                className={`valueBoxPercentage ${
                                                  mainData.txirr_percentage *
                                                    1 <
                                                  0
                                                    ? "red"
                                                    : "green"
                                                }`}
                                              >
                                                <span>
                                                  {mainData.txirr_percentage > 0
                                                    ? +mainData.txirr_percentage
                                                    : mainData.txirr_percentage}
                                                  %
                                                </span>
                                                <FaLongArrowAltUp />
                                              </p>
                                            )}
                                            {returnsType.header ==
                                              "absolute" && (
                                              <p
                                                className={`valueBoxPercentage ${
                                                  mainData.tabs_return_percentage *
                                                    1 <
                                                  0
                                                    ? "red"
                                                    : "green"
                                                }`}
                                              >
                                                <span>
                                                  {mainData.tabs_return_percentage >
                                                  0
                                                    ? +mainData.tabs_return_percentage
                                                    : mainData.tabs_return_percentage}
                                                  %
                                                </span>
                                                <FaLongArrowAltUp />
                                              </p>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="resultOptionsBox">
                                  <div className="resultOptions">
                                    <div>
                                      <div
                                        className="resultOptionsBtn position-relative hover-dropdown pointer"
                                        onClick={() =>
                                          setIsFilterPanelActive(
                                            (prev) => !prev
                                          )
                                        }
                                      >
                                        <HiSortAscending fontSize={"1.2rem"} />
                                        <span>Sort & Filter</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="fixedHeaders">
                            <div className="table-responsive">
                              {/* <div class="ptTableBx fundNameCon">
                                <div class="d-flex fn-inner-summary pt-1">
                                  <div class="d-flex">
                                    <span
                                      title="Active SIP"
                                      className={`${style["fund-tick-active"]} fund-tick`}
                                    ></span>
                                    <span>Active SIP</span>
                                  </div>
                                  <div class="d-flex">
                                    <span
                                      title="Inactive SIP"
                                      className={`${style["fund-tick-inactive"]} fund-tick`}
                                    ></span>
                                    <span>Stopped SIP</span>
                                  </div>
                                  <div class="d-flex">
                                    <span
                                      title="Offline SIP"
                                      className={`${style["fund-tick-offline"]} fund-tick`}
                                    ></span>
                                    <span>Offline Transaction</span>
                                  </div>
                                  <div class="d-flex">
                                    <span
                                      title="Order Mapping Required"
                                      className={`${style["fund-tick-error"]} fund-tick`}
                                    ></span>
                                    <span>Order Mapping Required</span>
                                  </div>
                                </div>
                              </div> */}
                              <div className="ptTableBx">
                                {Boolean(
                                  "fund_details" in mainData &&
                                    mainData.fund_details.length
                                ) && (
                                  <Table
                                    className={`ptTable ${style.mfTable} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                  >
                                    <thead>
                                      <tr>
                                        <td scope="col">Funds</td>
                                        <td scope="col">
                                          <p className="mb-1">Current Value</p>
                                          <p
                                            className="mb-0"
                                            style={{
                                              fontWeight: 500,
                                              fontSize: "0.8rem",
                                            }}
                                          >
                                            Invested
                                          </p>
                                        </td>
                                        <td scope="col" className="align-top">
                                          <p className="mb-1">Gain | Loss</p>
                                          <p
                                            className="mb-0"
                                            style={{
                                              fontWeight: 500,
                                              fontSize: "0.8rem",
                                            }}
                                          >
                                            Day Change
                                          </p>
                                        </td>
                                        <td scope="col">
                                          <div
                                            className="d-flex align-items-center pointer"
                                            onClick={() =>
                                              setReturnsType((prev) => ({
                                                ...prev,
                                                insideTable:
                                                  prev.insideTable == "xirr"
                                                    ? "absolute"
                                                    : "xirr",
                                              }))
                                            }
                                          >
                                            <span className="pe-2">
                                              {returnsType.insideTable == "xirr"
                                                ? "XIRR"
                                                : "Absolute"}{" "}
                                              %
                                            </span>
                                            <DownArrow
                                              width={"12px"}
                                              height={"12px"}
                                            />
                                          </div>
                                        </td>
                                        <td scope="col"></td>
                                      </tr>
                                    </thead>
                                  </Table>
                                )}
                              </div>
                            </div>
                          </div>
                          {console.log("mainData", mainData)}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {"fund_details" in mainData &&
                                  mainData.fund_details.map((v) => (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="fundNameTd"
                                        data-label="Funds"
                                      >
                                        <div className="fundName9">
                                          <div className="position-relative">
                                            <img
                                              src={getPublicMediaURL(
                                                `/static/media/companyicons/${v.amc_code}.png`
                                              )}
                                              onError={(e) => {
                                                e.target.src = `${process.env.REACT_APP_STATIC_URL}/media/companyicons/amc_icon.png`;
                                                e.onError = null;
                                              }}
                                            />
                                            {(
                                              v?.inv_type || ""
                                            ).toLowerCase() == "sip" && (
                                              <span
                                                title="Active SIP"
                                                className={`${
                                                  v.sip_status == "active"
                                                    ? style["fund-tick-active"]
                                                    : style[
                                                        "fund-tick-inactive"
                                                      ]
                                                } ${style["fund-tick-"]} `}
                                              ></span>
                                            )}
                                          </div>
                                          <div className="fundNameCon">
                                            <div className="mb-2">
                                              {v.inv_type && (
                                                <span
                                                  className={`me-2 ${style["invtype-badge"]} ${style["rounded-badge"]}`}
                                                >
                                                  {v.inv_type}
                                                </span>
                                              )}
                                              {v.fund_registrar == "ecas" && (
                                                <span
                                                  className={`${style["registrar-badge"]} ${style["rounded-badge"]}`}
                                                >
                                                  External
                                                </span>
                                              )}
                                              <strong className="investor-name orange">
                                                {v.investor_name}
                                              </strong>
                                            </div>
                                            <div
                                              className="fnc-yy"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <strong
                                                onClick={() => detailsPage(v)}
                                              >
                                                {v.scheme}
                                              </strong>
                                            </div>
                                            <div className="d-flex fn-inner-summary pt-1">
                                              <div>Folio: {v.folio_no}</div>
                                              <div>NAV: {v.curr_nav}</div>
                                              <div>Units: {v.units}</div>
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Current Value"
                                        className=""
                                      >
                                        <div>
                                          <strong>
                                            {" "}
                                            {indianRupeeFormat(v.curr_val)}
                                          </strong>
                                        </div>
                                        <p
                                          className="mb-0 pt-1"
                                          style={{
                                            fontWeight: 300,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          {" "}
                                          {indianRupeeFormat(v.inv)}
                                        </p>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Gain | Loss"
                                        className=""
                                      >
                                        <div>
                                          <strong
                                            className={`xrr-returns ${
                                              v.xirr_percentage < 0
                                                ? "red"
                                                : "green"
                                            }`}
                                          >
                                            {indianRupeeFormat(v.gain_loss)}
                                          </strong>
                                        </div>
                                        <p
                                          className="mb-0 pt-1"
                                          style={{
                                            fontWeight: 300,
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          <span
                                            className={` xrr-returns ${
                                              v.day_change < 0 ? "red" : "green"
                                            }`}
                                          >
                                            {indianRupeeFormat(v.day_change)}
                                            &nbsp;
                                          </span>
                                          <span
                                            className={`xrr-returns ${
                                              v.day_change_perc < 0
                                                ? "red"
                                                : "green"
                                            }`}
                                          >
                                            ({v.day_change_perc}%)
                                          </span>
                                        </p>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="XIRR %"
                                        className=""
                                      >
                                        <div>
                                          <strong
                                            className={`xrr-returns ${
                                              v.xirr_percentage < 0
                                                ? "red"
                                                : "green"
                                            }`}
                                          >
                                            {returnsType.insideTable == "xirr"
                                              ? v.xirr_percentage != "Error"
                                                ? v.xirr_percentage
                                                : 0
                                              : v.abs_return_percentage}
                                          </strong>
                                        </div>
                                      </td>
                                      <td className="">
                                        {/* <Link
                                to={"/mutual-fund/portfolio/dashboard/fund"}
                              > */}
                                        <p
                                          onClick={() => detailsPage(v)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <ExploreStock />
                                          {/* <FaChevronRight /> */}
                                        </p>
                                        {/* </Link> */}
                                      </td>
                                    </tr>
                                  ))}
                                {Boolean(
                                  "fund_details" in mainData &&
                                    mainData.fund_details.length
                                ) === false && (
                                  <tr>
                                    <div className="d-flex justify-content-end">
                                      <div style={{ width: "10rem" }}>
                                        {resetFilterTriggerState.filtersActive && (
                                          <div>
                                            <div
                                              className="resultOptionsBtn position-relative hover-dropdown pointer"
                                              onClick={() =>
                                                setIsFilterPanelActive(
                                                  (prev) => !prev
                                                )
                                              }
                                            >
                                              <HiSortAscending
                                                fontSize={"1.2rem"}
                                              />
                                              <span>Sort & Filter</span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="w-50 m-auto p-5">
                                      <BulletPoint
                                        heading={
                                          "Track and manage your mutual fund"
                                        }
                                        text={`Across multiple brokers at one place. Always stay on top of your
          holdings`}
                                      />
                                      <BulletPoint
                                        heading={`Real time analysis of your mutual fund performance`}
                                        text={`Powerful and in-depth analysis on all your holdings with actionable insights`}
                                      />
                                      <BulletPoint
                                        heading={`Get advisory on your mutual fund portfolio`}
                                        text={`Real time investment advisory, super-charge your portfolio's performance!`}
                                      />

                                      <div className="pt-3">
                                        <ActionButton
                                          label={"Add mutual fund now"}
                                          onClick={() => {
                                            navigate(
                                              process.env.PUBLIC_URL +
                                                "/mutual-fund/funds/all"
                                            );
                                          }}
                                        />
                                        <ActionButton
                                          label={"Fetch your external holdings"}
                                          onClick={() => {
                                            navigate(
                                              process.env.PUBLIC_URL +
                                                "/mutual-fund/portfolio/link-your-holdings"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ================================================================= MF table end ================================================================= */}
                  <div>
                    {isDataLoading.otherInvestmentData &&
                    searchParams.get("assetTabNumber") != 1 ? (
                      <div className={`insideTabContent tbl-02 px-2 px-md-4`}>
                        <Table
                          responsive
                          className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-1">
                                <div className="shine"></div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div>
                        {/* insurance section */}
                        <div
                          className={`insideTabContent tbl-03 px-2 px-md-4 ${
                            selectedTab == 2 ? "" : "d-none"
                          }`}
                        >
                          {"insurance_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.insurance_data)
                                .length
                            ) && (
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-none d-md-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          No. Of Policies
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.insurance_data
                                            ?.no_of_policies != undefined
                                            ? otherInvestmentData.insurance_data
                                                .no_of_policies
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Premium Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.insurance_data
                                            ?.total_premium_amount
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .insurance_data
                                                  .total_premium_amount
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Sum Assured
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.insurance_data
                                            ?.total_risk_coverage
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .insurance_data
                                                  .total_risk_coverage
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {process.env.REACT_APP_MODE == "stg" && (
                                  <div
                                    className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                  >
                                    {getItemLocal("family") ? (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-insurance"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Insurance</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-insurance"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Insurance</span>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          {"insurance_data" in otherInvestmentData &&
                            Object.keys(otherInvestmentData.insurance_data)
                              .length > 0 && (
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style["portfolio-insurance-table"]} ${style["bold-table"]} mb-0`}
                                    >
                                      <thead>
                                        <tr>
                                          {/* <td
                                            scope="col"
                                                                                  >
                                            Policy Name
                                          </td>
                                          <td scope="col">Policy Number</td>
                                          <td scope="col">Insurance Type</td>
                                          <td scope="col">Premium (&#x20B9;)</td>
                                          <td scope="col">
                                            Sum assured (&#x20B9;)
                                          </td>
                                          <td scope="col">Start Date</td>
                                          <td scope="col">Term Date</td> */}

                                          <td scope="col">Company Name</td>
                                          <td scope="col">Policy</td>
                                          <td scope="col">Insurance Type</td>
                                          <td scope="col">Premium</td>
                                          <td scope="col">Risk Coverage</td>
                                          <td scope="col">Start Date</td>
                                          <td scope="col">Maturity Date</td>
                                          {/* <td scope="col">&nbsp;</td> */}
                                          {process.env.REACT_APP_MODE ==
                                            "stg" && (
                                            <td
                                              scope="col"
                                              style={{ width: "10%" }}
                                            >
                                              &nbsp;
                                            </td>
                                          )}
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.dataTable} ${style["portfolio-insurance-table"]} mb-0`}
                            >
                              <tbody>
                                {otherInvestmentData?.insurance_data
                                  ?.insurance_details ? (
                                  otherInvestmentData.insurance_data.insurance_details.map(
                                    (v) => (
                                      <tr
                                        className={`${style.tableRowStyle}`}
                                        style={{
                                          borderBottom:
                                            "1px solid black !important",
                                        }}
                                      >
                                        {/* <td
                                          scope="row"
                                          data-label="Policy Name"
                                                                              >
                                          <div className="d-flex">
                                            <div className="pe-3">
                                              <img
                                                class={`accordian-img ${style.tblIcons}`}
                                                src={
                                                  process.env
                                                    .REACT_APP_STATIC_URL_PYTHON +
                                                  "/assets/img/insurance/insurance_insurance_form.svg"
                                                }
                                              />
                                            </div>
                                            <div>
                                              {v.insurance_company_name == null
                                                ? v.policy_name != ""
                                                  ? v.policy_name
                                                  : "-"
                                                : v.insurance_company_name}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Policy Number"
                                        >
                                                                                  {v.policy_no ? v.policy_no : "-"}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Insurance Type"
                                          className=""
                                        >
                                          {v.insurance_type
                                            ? v.insurance_type
                                            : "-"}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Premium"
                                          className=""
                                        >
                                          {indianRupeeFormat(v.premium_amount)}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Sum assured"
                                          className=""
                                        >
                                          {indianRupeeFormat(v.risk_coverage)}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Start Date"
                                          className=""
                                        >
                                          {v?.policy_start_date
                                            ? moment(v.policy_start_date).format(
                                              "MM/DD/YYYY"
                                            )
                                            : "-"}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Term Date"
                                          className=""
                                        >
                                          {v?.maturitydate
                                            ? moment(v.maturitydate).format(
                                              "MM/DD/YYYY"
                                            )
                                            : "-"}
  </td> */}
                                        <td
                                          scope="row"
                                          className={style.policyCompany}
                                          data-label="Funds"
                                        >
                                          <strong
                                            onClick={() => detailsPage(v)}
                                          >
                                            {v.insurance_company_name}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Invested"
                                          className={style.policyNumber}
                                        >
                                          <strong>
                                            {v.insurance_company_name}
                                          </strong>
                                          <br />
                                          <strong className={style.smallfont}>
                                            Policy No.: {v.policy_no}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong>{v.insurance_type}</strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong>
                                            {indianRupeeFormat(
                                              v.premium_amount
                                            )}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Units"
                                          className=""
                                        >
                                          <strong>
                                            {indianRupeeFormat(v.risk_coverage)}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Gain | Loss"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.policy_start_date}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.maturitydate}
                                          </strong>
                                        </td>
                                        {process.env.REACT_APP_MODE ==
                                          "stg" && (
                                          <td>
                                            <div
                                              className={
                                                style.actionBtnsContainer
                                              }
                                            >
                                              {process.env.REACT_APP_MODE ===
                                                "stg" && (
                                                <>
                                                  {" "}
                                                  <i
                                                    className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                                  ></i>
                                                  {getItemLocal("family") ? (
                                                    <i
                                                      className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                    ></i>
                                                  ) : (
                                                    <Link
                                                      to={
                                                        process.env.PUBLIC_URL +
                                                        "/commondashboard/investment/new-insurance/" +
                                                        btoa(v.insurance_id)
                                                      }
                                                    >
                                                      <i
                                                        className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                      ></i>
                                                    </Link>
                                                  )}
                                                </>
                                              )}

                                              {getItemLocal("family") ? (
                                                <i
                                                  class={`disabled fa fa-trash ${style.trash}`}
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <i
                                                  class={`fa fa-trash ${style.trash}`}
                                                  onClick={() =>
                                                    Swal.fire({
                                                      title:
                                                        "Are you sure you want to delete?",
                                                      showConfirmButton: true,
                                                      showCancelButton: true,
                                                      confirmButtonText: "Yes",
                                                      cancelButtonText: "No",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteInsuranceItem(
                                                          v.insurance_id,
                                                          "insurance",
                                                          v.fp_log_id
                                                            ? v.fp_log_id
                                                            : ""
                                                        );
                                                      }
                                                    })
                                                  }
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Insurance Policy data to display
                                    </p>
                                    {process.env.REACT_APP_MODE === "stg" && (
                                      <p className="text-center fw-bold">
                                        You can add your existing Insurance
                                        Policy
                                      </p>
                                    )}

                                    <div className="text-center">
                                      {/* {process.env.REACT_APP_MODE === "stg" && (
                                        <>
                                          {" "}
                                          {getItemLocal("family") ? (
                                            <>
                                              <Link
                                                className="disabled anchor-primary"
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-insurance"
                                                }
                                              >
                                                Add Insurance
                                              </Link>
                                            </>
                                          ) : (
                                            <>
                                              <Link
                                                className="anchor-primary"
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-insurance"
                                                }
                                              >
                                                Add Insurance
                                              </Link>
                                            </>
                                          )}
                                        </>
                                      )} */}
                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/Group-162.png"
                                        }
                                        style={{ width: 400 }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* fd section */}
                        <div
                          className={`insideTabContent tbl-04 px-2 px-md-4 ${
                            selectedTab == 3 ? "" : "d-none"
                          }`}
                        >
                          {"fdbond_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.fdbond_data)
                                .length
                            ) && (
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-none d-md-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          No. Of FD Bonds
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.fdbond_data
                                            ?.no_of_fdbonds != undefined
                                            ? otherInvestmentData.fdbond_data
                                                .no_of_fdbonds
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Purchase Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.fdbond_data
                                            ?.total_amount
                                            ? indianRupeeFormat(
                                                Math.round(
                                                  otherInvestmentData
                                                    .fdbond_data.total_amount
                                                )
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Maturity Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.fdbond_data
                                            ?.total_maturity
                                            ? indianRupeeFormat(
                                                Math.round(
                                                  otherInvestmentData
                                                    .fdbond_data.total_maturity
                                                )
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {process.env.REACT_APP_MODE == "stg" && (
                                  <div
                                    className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                  >
                                    {getItemLocal("family") ? (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-fd-bonds"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add FD & Bonds</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-fd-bonds"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add FD & Bonds</span>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          {"fdbond_data" in otherInvestmentData &&
                            Object.keys(otherInvestmentData.fdbond_data)
                              .length > 0 && (
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style["portfolio-fd-table"]} ${style.fdTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          {/* <td scope="col">Name of Asset</td>
                                          <td scope="col">Amount</td>
                                          <td scope="col">Tenure (Days)</td>
                                          <td scope="col">
                                            Interest&nbsp;/&nbsp;Frequency
                                          </td>
                                          <td scope="col">Maturity Amount</td>
                                          <td scope="col">Start Date</td>
                                          <td scope="col">Maturity Date</td>
                                          <td scope="col">Status</td>
                                          <td scope="col">&nbsp;</td> */}
                                          <td scope="col">Scheme Name</td>
                                          <td scope="col">Company Name</td>
                                          <td scope="col">Amount</td>
                                          <td scope="col">Tenure (Days)</td>
                                          <td
                                            scope="col"
                                            style={{ width: "10%" }}
                                          >
                                            Interest / Frequency
                                          </td>
                                          <td scope="col">Maturity Amount</td>
                                          <td scope="col">Start Date</td>
                                          <td scope="col">Maturity Date</td>
                                          <td scope="col">Status</td>
                                          {process.env.REACT_APP_MODE ==
                                            "stg" && (
                                            <td scope="col">&nbsp;</td>
                                          )}
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            )}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style["portfolio-fd-table"]} ${style["actual-data-table"]} ${style.fdTbl} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.fdbond_data
                                  ?.fdbond_details ? (
                                  otherInvestmentData.fdbond_data.fdbond_details.map(
                                    (v) => (
                                      <tr>
                                        {/* <td
                                          scope="row"
                                          data-label="Name of Asset"
                                        >
                                          <AssetName
                                            title={v.scheme_name}
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "/assets/img/insurance/insurance_insurance_form.svg"
                                            }
                                          />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Amount"
                                          className=""
                                        >
                                          {indianRupeeFormat(
                                            Math.round(v.amount)
                                          )}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Tenure (Days)"
                                          className=""
                                        >
                                          {v.tenure}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Interest / Frequency"
                                          className=""
                                        >
                                          {v.interest_rate
                                            ? v.interest_rate
                                            : "-"}
                                          % /{" "}
                                          {v.interest_compounding
                                            ? v.interest_compounding
                                            : "-"}
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Maturity Amount"
                                          className=""
                                        >
                                          <span className={`xrr-returns`}>
                                            {indianRupeeFormat(
                                              Math.round(
                                                v.maturity_value
                                                  ? v.maturity_value * 1
                                                  : "-"
                                              )
                                            )}
                                          </span>
                                        </td>
                                                                              <td
                                          scope="row"
                                          data-label="Start Date"
                                          className=""
                                        >
                                          <span className={`xrr-returns`}>
                                            {v.investment_date
                                              ? moment(v.investment_date).format(
                                                "DD/MM/YYYY"
                                              )
                                              : "-"}
                                          </span>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Maturity Date"
                                          className=""
                                        >
                                          <span className={`xrr-returns`}>
                                            {v.maturitydate
                                              ? moment(v.maturitydate).format(
                                                "DD/MM/YYYY"
                                              )
                                              : "-"}
                                          </span>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Status"
                                          className=""
                                        >
                                          <span className={`xrr-returns`}>
                                            {v.status == "1" || v.status == "3"
                                              ? "Active"
                                              : "Inactive"}
                                          </span>
  </td> */}

                                        <td scope="row" data-label="Funds">
                                          <strong
                                            onClick={() => detailsPage(v)}
                                          >
                                            {v.scheme_name}
                                          </strong>
                                        </td>
                                        <td scope="row" data-label="Invested">
                                          <strong>{v.issuer}</strong>
                                          <br />
                                          <strong className={style.smallfont}>
                                            Issuer Type: {v.issuer_type}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong>
                                            {indianRupeeFormat(v.amount)}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong>{v.tenure}</strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Units"
                                          className=""
                                        >
                                          <strong>
                                            {v.interest_rate}% /{" "}
                                            {v.interest_compounding}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Gain | Loss"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {indianRupeeFormat(
                                              v.maturity_value
                                            )}
                                          </strong>
                                        </td>

                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.investment_date}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.maturitydate}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.status == "1"
                                              ? "Active"
                                              : "Inactive"}
                                          </strong>
                                        </td>
                                        {process.env.REACT_APP_MODE ==
                                          "stg" && (
                                          <td>
                                            <div
                                              className={
                                                style.actionBtnsContainer
                                              }
                                            >
                                              {process.env.REACT_APP_MODE ===
                                                "stg" && (
                                                <>
                                                  <i
                                                    className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                                  ></i>
                                                  {getItemLocal("family") ? (
                                                    <i
                                                      className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                    ></i>
                                                  ) : (
                                                    <Link
                                                      to={
                                                        process.env.PUBLIC_URL +
                                                        "/commondashboard/investment/new-fd-bonds/" +
                                                        btoa(v.id)
                                                      }
                                                    >
                                                      <i
                                                        className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                      ></i>
                                                    </Link>
                                                  )}
                                                </>
                                              )}

                                              {getItemLocal("family") ? (
                                                <i
                                                  class={`disabled fa fa-trash ${style.trash}`}
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <i
                                                  class={`fa fa-trash ${style.trash}`}
                                                  onClick={() =>
                                                    Swal.fire({
                                                      title:
                                                        "Are you sure you want to delete?",
                                                      showConfirmButton: true,
                                                      showCancelButton: true,
                                                      confirmButtonText: "Yes",
                                                      cancelButtonText: "No",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteInsuranceItem(
                                                          v.id,
                                                          "fd_bond",
                                                          v.fp_log_id
                                                            ? v.fp_log_id
                                                            : ""
                                                        );
                                                      }
                                                    })
                                                  }
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Bonds, Fixed Deposit data to display
                                    </p>
                                    {process.env.REACT_APP_MODE === "stg" && (
                                      <p className="text-center fw-bold">
                                        You can add your existing Debt Assets
                                      </p>
                                    )}

                                    <div className="text-center">
                                      {/* {process.env.REACT_APP_MODE === "stg" && (
                                        <>
                                          {getItemLocal("family") ? (
                                            <Link
                                              className="disabled anchor-primary"
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-fd-bonds?type=fd"
                                              }
                                            >
                                              Add Existing Asset
                                            </Link>
                                          ) : (
                                            <Link
                                              className="anchor-primary"
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-fd-bonds?type=fd"
                                              }
                                            >
                                              Add Existing Asset
                                            </Link>
                                          )}
                                        </>
                                      )} */}

                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/Group-162.png"
                                        }
                                        style={{ width: 400 }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* govt scheme section */}
                        <div
                          className={`govtschemetable insideTabContent tbl-05 px-2 px-md-4 ${
                            selectedTab == 4 ? "" : "d-none"
                          }`}
                        >
                          {"po_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.po_data).length
                            ) && (
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          No. Of Schemes
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.po_data
                                            ?.no_of_po_schemes != undefined
                                            ? otherInvestmentData.po_data
                                                .no_of_po_schemes
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Purchase Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.po_data
                                            ?.total_amount
                                            ? indianRupeeFormat(
                                                otherInvestmentData.po_data
                                                  .total_amount * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Current Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.po_data
                                            ?.total_current
                                            ? indianRupeeFormat(
                                                otherInvestmentData.po_data
                                                  .total_current * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Maturity Amount
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.po_data
                                            ?.total_maturity
                                            ? indianRupeeFormat(
                                                otherInvestmentData.po_data
                                                  .total_maturity * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {"po_data" in otherInvestmentData &&
                            Object.keys(otherInvestmentData.po_data).length >
                              0 && (
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.govtSchemeTbl} mb-0 ${style["bold-table"]} ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">Scheme Name</td>
                                          <td scope="col">Account No.</td>
                                          <td scope="col">Purchase Amount</td>
                                          <td scope="col">Start Date</td>
                                          <td scope="col">Interest Rate (%)</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">Maturity Date</td>
                                          <td scope="col">Maturity Value</td>
                                          {process.env.REACT_APP_MODE ==
                                            "stg" && (
                                            <td scope="col">&nbsp;</td>
                                          )}
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            )}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.govtSchemeTbl} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.po_data?.po_details ? (
                                  otherInvestmentData.po_data.po_details.map(
                                    (v) => (
                                      <tr>
                                        <td scope="row" data-label="Funds">
                                          <AssetName
                                            title={v.scheme_name}
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "/assets/img/insurance/insurance_insurance_form.svg"
                                            }
                                          />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Units"
                                          className=""
                                        >
                                          <strong>
                                            {v.acc_no ? v.acc_no : "-"}
                                          </strong>
                                        </td>
                                        <td scope="row" data-label="Invested">
                                          <strong>
                                            {indianRupeeFormat(
                                              v.amount ? v.amount * 1 : "-"
                                            )}
                                          </strong>
                                          <br />
                                          {/* <strong className={style.smallfont}>
                                    Policy No.: {v.policy_no}
                                  </strong> */}
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.investment_date
                                              ? moment(
                                                  v.investment_date
                                                ).format("DD/MM/YYYY")
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong>
                                            {v.interest
                                              ? parseFloat(v.interest) + "%"
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Gain | Loss"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            <strong className={`xrr-returns`}>
                                              {v.asset_current_unit_price
                                                ? indianRupeeFormat(
                                                    v.asset_current_unit_price *
                                                      1
                                                  )
                                                : "-"}
                                            </strong>
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.date_maturity
                                              ? moment(v.date_maturity).format(
                                                  "DD/MM/YYYY"
                                                )
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="XIRR %"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.maturity_amount
                                              ? indianRupeeFormat(
                                                  v.maturity_amount * 1
                                                )
                                              : "-"}
                                          </strong>
                                        </td>
                                        {process.env.REACT_APP_MODE ==
                                          "stg" && (
                                          <td>
                                            <div
                                              className={
                                                style.actionBtnsContainer
                                              }
                                            >
                                              {process.env.REACT_APP_MODE ===
                                                "stg" && (
                                                <>
                                                  <i
                                                    className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                                  ></i>
                                                  {getItemLocal("family") ? (
                                                    <>
                                                      <i
                                                        className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Link
                                                        to={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/commondashboard/investment/new-govt-scheme/" +
                                                          btoa(v.realestate_id)
                                                        }
                                                      >
                                                        <i
                                                          className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  )}
                                                </>
                                              )}

                                              {getItemLocal("family") ? (
                                                <i
                                                  class={`disabled fa fa-trash ${style.trash}`}
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <i
                                                  class={`fa fa-trash ${style.trash}`}
                                                  onClick={() =>
                                                    Swal.fire({
                                                      title:
                                                        "Are you sure you want to delete?",
                                                      showConfirmButton: true,
                                                      showCancelButton: true,
                                                      confirmButtonText: "Yes",
                                                      cancelButtonText: "No",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteInsuranceItem(
                                                          v.post_id,
                                                          "po",
                                                          v.fp_log_id
                                                            ? v.fp_log_id
                                                            : ""
                                                        );
                                                      }
                                                    })
                                                  }
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    {/* <p style={{ textAlign: "center", fontWeight: "700" }}>
                                No investment(s) found!
                              </p> */}
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems like we don't have
                                        your Government Scheme data to display
                                      </p>
                                      {process.env.REACT_APP_MODE === "stg" && (
                                        <p className="text-center fw-bold">
                                          You can add your existing Government
                                          Schemes
                                        </p>
                                      )}

                                      <div className="text-center">
                                        {/* {process.env.REACT_APP_MODE ===
                                          "stg" && (
                                          <>
                                            {" "}
                                            {getItemLocal("family") ? (
                                              <Link
                                                className="disabled anchor-primary"
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-govt-scheme"
                                                }
                                              >
                                                Add Existing Scheme
                                              </Link>
                                            ) : (
                                              <Link
                                                className="anchor-primary"
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-govt-scheme"
                                                }
                                              >
                                                Add Existing Scheme
                                              </Link>
                                            )}
                                          </>
                                        )} */}
                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/Group-162.png"
                                          }
                                          style={{ width: 400 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* real estate section */}
                        <div
                          className={`insideTabContent tbl-06 px-2 px-md-4 ${
                            selectedTab == 5 ? "" : "d-none"
                          }`}
                        >
                          {"real_estate_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.real_estate_data)
                                .length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-9">
                                    <div className="d-block">
                                      <div className="cntResults">
                                        <div className={style.ecntRItems}>
                                          <div className={style.borderSpace}>
                                            No. Of Properties
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData
                                              ?.real_estate_data
                                              ?.no_of_properties != undefined
                                              ? otherInvestmentData
                                                  .real_estate_data
                                                  .no_of_properties
                                              : "-"}
                                          </div>
                                        </div>
                                        <div className={style.ecntRItems}>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Purchased Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData
                                              ?.real_estate_data
                                              ?.total_purchase_rate
                                              ? indianRupeeFormat(
                                                  otherInvestmentData
                                                    .real_estate_data
                                                    .total_purchase_rate * 1
                                                )
                                              : "-"}
                                          </div>
                                        </div>
                                        <div
                                          className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                        >
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData
                                              ?.real_estate_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                  otherInvestmentData
                                                    .real_estate_data
                                                    .total_current_rate * 1
                                                )
                                              : "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {process.env.REACT_APP_MODE == "stg" && (
                                    <div
                                      className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                    >
                                      <div className={`${style.addBtn}`}>
                                        {getItemLocal("family") ? (
                                          <Link
                                            className={`disabled anchor-primary ${style.linkStyle}`}
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-real-eatate"
                                            }
                                          >
                                            <span>
                                              <i className="fa-solid fa-plus"></i>
                                            </span>{" "}
                                            <span>Add Real Estate</span>
                                          </Link>
                                        ) : (
                                          <>
                                            <Link
                                              className={`anchor-primary ${style.linkStyle}`}
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-real-eatate"
                                              }
                                            >
                                              <span>
                                                <i className="fa-solid fa-plus"></i>
                                              </span>{" "}
                                              <span>Add Real Estate</span>
                                            </Link>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable mb-0 ${style["bold-table"]} ${style.realEstateTable} ${style.headerTable}`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">
                                              Name Of Property
                                            </td>
                                            <td scope="col">
                                              Type of Property
                                            </td>
                                            <td scope="col">Purchased Value</td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">Returns</td>
                                            <td scope="col">
                                              Absolute Returns %
                                            </td>
                                            {process.env.REACT_APP_MODE ==
                                              "stg" && (
                                              <td scope="col">&nbsp;</td>
                                            )}
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.realEstateTable} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.real_estate_data
                                  ?.real_estate_details ? (
                                  otherInvestmentData.real_estate_data.real_estate_details.map(
                                    (v) => (
                                      <tr
                                        key={v.realestate_id}
                                        className={`ptTableBx `}
                                      >
                                        <td
                                          scope="row"
                                          data-label="Type/Name Of Property"
                                        >
                                          <AssetName
                                            title={
                                              v.property_name
                                                ? v.property_name
                                                : "-"
                                            }
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "assets/img/assets-liabilities/assets_real_estate.svg"
                                            }
                                          />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Residential Type"
                                        >
                                          <strong>
                                            {v.property_type !== undefined
                                              ? v.property_type
                                              : v?.asset_type !== undefined
                                              ? v.asset_type
                                              : "-"}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Purchased Value"
                                        >
                                          <strong className={`xrr-returns`}>
                                            {/* {v.purchase_date
                                      ? moment(v.purchase_date).format(
                                        "DD/MM/YYYY"
                                      )
                                      : "-"} */}
                                            {v.purchase_rate
                                              ? indianRupeeFormat(
                                                  v.purchase_rate * 1
                                                )
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                        >
                                          <strong>
                                            {v.current_rate
                                              ? indianRupeeFormat(
                                                  v.current_rate * 1
                                                )
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td scope="row" data-label="Returns">
                                          <strong
                                            className={`xrr-returns ${
                                              v.current_rate - v.purchase_rate <
                                              0
                                                ? "red"
                                                : "green"
                                            }`}
                                          >
                                            {v.current_rate - v.purchase_rate
                                              ? indianRupeeFormat(
                                                  v.current_rate -
                                                    v.purchase_rate * 1
                                                )
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Absolute Returns"
                                        >
                                          <strong
                                            className={`xrr-returns ${
                                              v?.purchase_rate !== 0
                                                ? ((v?.current_rate -
                                                    v?.purchase_rate) /
                                                    v?.purchase_rate) *
                                                    100 <
                                                  0
                                                  ? "red"
                                                  : "green"
                                                : "default-class"
                                            }`}
                                          >
                                            {v?.purchase_rate !== 0
                                              ? (
                                                  ((v?.current_rate -
                                                    v?.purchase_rate) /
                                                    v?.purchase_rate) *
                                                  100
                                                ).toFixed(2)
                                              : "-"}
                                          </strong>
                                        </td>
                                        {process.env.REACT_APP_MODE ==
                                          "stg" && (
                                          <td>
                                            <div
                                              className={
                                                style.actionBtnsContainer
                                              }
                                            >
                                              {process.env.REACT_APP_MODE ===
                                                "stg" && (
                                                <>
                                                  <i
                                                    className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                                  ></i>
                                                  {getItemLocal("family") ? (
                                                    <>
                                                      <i
                                                        className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                      ></i>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Link
                                                        to={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/commondashboard/investment/new-real-eatate/" +
                                                          btoa(v.realestate_id)
                                                        }
                                                      >
                                                        <i
                                                          className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                        ></i>
                                                      </Link>
                                                    </>
                                                  )}
                                                </>
                                              )}

                                              {getItemLocal("family") ? (
                                                <i
                                                  class={`disabled fa fa-trash ${style.trash}`}
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <i
                                                  class={`fa fa-trash ${style.trash}`}
                                                  onClick={() =>
                                                    Swal.fire({
                                                      title:
                                                        "Are you sure you want to delete?",
                                                      showConfirmButton: true,
                                                      showCancelButton: true,
                                                      confirmButtonText: "Yes",
                                                      cancelButtonText: "No",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteInsuranceItem(
                                                          v.realestate_id,
                                                          "real_estate",
                                                          v.fp_log_id
                                                            ? v.fp_log_id
                                                            : ""
                                                        );
                                                      }
                                                    })
                                                  }
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems like we don't have
                                        your Real Estate Data to display
                                      </p>
                                      {process.env.REACT_APP_MODE === "stg" && (
                                        <p className="text-center fw-bold">
                                          You can add your existing Real Estate
                                        </p>
                                      )}

                                      <div className="text-center">
                                        {/* {process.env.REACT_APP_MODE ===
                                          "stg" && (
                                          <>
                                            {" "}
                                            {getItemLocal("family") ? (
                                              <>
                                                <Link
                                                  className="disabled anchor-primary"
                                                  to={
                                                    process.env.PUBLIC_URL +
                                                    "/commondashboard/investment/new-real-eatate"
                                                  }
                                                >
                                                  Add Existing Real Estate
                                                </Link>
                                              </>
                                            ) : (
                                              <>
                                                <Link
                                                  className="anchor-primary"
                                                  to={
                                                    process.env.PUBLIC_URL +
                                                    "/commondashboard/investment/new-real-eatate"
                                                  }
                                                >
                                                  Add Existing Real Estate
                                                </Link>
                                              </>
                                            )}
                                          </>
                                        )} */}
                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/Group-162.png"
                                          }
                                          style={{ width: 400 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {/* ============================================================================== new tabs begin================================================================================== */}
                        {/* alternate section */}
                        {/* <div
                          className={`insideTabContent tbl-07 px-2 px-md-4 ${selectedTab == 6 ? "" : "d-none"
                            }`}
                        >
                          {"alternate_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.alternate_data)
                                .length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-10">
                                    <div className="d-none d-md-block">
                                      <div className="cntResults">
                                        <div className={style.ecntRItems}>
                                          <div className={style.borderSpace}>
                                            Total Alternate Assets
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData?.alternate_data
                                              ?.no_of_assets != undefined
                                              ? otherInvestmentData.alternate_data
                                                .no_of_assets
                                              : "-"}
                                          </div>
                                        </div>
                                        <div className={style.ecntRItems}>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Invested Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.alternate_data
                                              ?.total_purchase_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .alternate_data
                                                  .total_purchase_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                        <div
                                          className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                        >
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.alternate_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .alternate_data
                                                  .total_current_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-12">
                                    {getItemLocal("family") ? (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-alternet-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Alternate</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className={`${style.addBtn}`}>
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-alternet-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Alternate</span>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable ${style.alternateTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">
                                              Name of Asset (Category)
                                            </td>
                                            <td scope="col">Invested Value</td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">Returns</td>
                                            <td scope="col">Absolute Return</td>
                                            <td scope="col">&nbsp;</td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="  ">
                            <Table
                              className={`ptTable fixedTable ${style.alternateTbl} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.alternate_data
                                  ?.alternate_details ? (
                                  otherInvestmentData?.alternate_data?.alternate_details.map(
                                    (v) => (
                                      <tr key={v.id}>
                                        <td scope="row" data-label="asset_name">
                                          <strong></strong>
                                          <AssetName
                                            title={
                                              v.asset_type ? v.asset_type : "-"
                                            }
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "/assets/img/assets-liabilities/assets_alternate.svg"
                                            }
                                          />
                                        </td>
                                        <td scope="row" data-label="invested_val">
                                          <strong>
                                            {v.inv_val != 0.0
                                              ? indianRupeeFormat(v.inv_val * 1)
                                              : "-"}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="curr_val"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.cr_val
                                              ? indianRupeeFormat(v.cr_val * 1)
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="returns"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v.rtn * 1 < 0
                                              ? "red"
                                              : v.rtn * 1 === 0 || v.rtn === "-"
                                                ? ""
                                                : "green"
                                              }`}
                                          >
                                            {v.rtn * 1 != 0
                                              ? indianRupeeFormat(v.rtn * 1)
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="absolute_rtn"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v.abs_rtn * 1 < 0
                                              ? "red"
                                              : v.abs_rtn * 1 === 0 ||
                                                v.abs_rtn === "-"
                                                ? ""
                                                : "green"
                                              }`}
                                          >
                                            {v.abs_rtn * 1 != 0
                                              ? v.abs_rtn + " %"
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td>
                                          <div
                                            className={style.actionBtnsContainer}
                                          >
                                            {
                                              process.env.REACT_APP_MODE === 'stg' && <><i
                                              className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                            ></i>
                                            {getItemLocal("family") ? (
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            ) : (
                                              <Link
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-alternet-asset/" +
                                                  btoa(v.id)
                                                }
                                              >
                                                <i
                                                  className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                ></i>
                                              </Link>
                                            )}</>
                                            }

                                            {getItemLocal("family") ? (
                                              <i
                                                class={`disabled fa fa-trash ${style.trash}`}
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <i
                                                class={`fa fa-trash ${style.trash}`}
                                                onClick={() =>
                                                  Swal.fire({
                                                    title:
                                                      "Are you sure you want to delete?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      deleteInsuranceItem(
                                                        v.id,
                                                        "alternate",
                                                        v.fp_log_id
                                                          ? v.fp_log_id
                                                          : ""
                                                      );
                                                    }
                                                  })
                                                }
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems like we don't have your
                                        Alternate Assets Data to display
                                      </p>
                                      {
                                        process.env.REACT_APP_MODE === 'stg' && <p className="text-center fw-bold">
                                        You can add your existing Alternate Assets
                                      </p>
  }

                                      <div className="text-center">
                                        {
                                          process.env.REACT_APP_MODE === 'stg' && <>{getItemLocal("family") ? (
                                          <Link
                                            className="disabled anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-alternet-asset"
                                            }
                                          >
                                            Add Alternate Assets
                                          </Link>
                                        ) : (
                                          <Link
                                            className="anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-alternet-asset"
                                            }
                                          >
                                            Add Alternate Assets
                                          </Link>
                                        )}</>
                                        }

                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/Group-177.png"
                                          }
                                          style={{ width: 400 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}
                        {/* gold section */}
                        {/* <div
                          className={`insideTabContent tbl-08 px-2 px-md-4 ${selectedTab == 7 ? "" : "d-none"
                            }`}
                        >
                          {"gold_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.gold_data).length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-9">
                                    <div className="d-block">
                                      <div className="cntResults">
                                        <div className={style.ecntRItems}>
                                          <div className={style.borderSpace}>
                                            Total Gold Assets
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData?.gold_data
                                              ?.no_of_assets != undefined
                                              ? otherInvestmentData.gold_data
                                                .no_of_assets
                                              : "-"}
                                          </div>
                                        </div>
                                        <div className={style.ecntRItems}>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Invested Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.gold_data
                                              ?.total_purchase_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.gold_data
                                                  .total_purchase_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                        <div
                                          className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                        >
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.gold_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.gold_data
                                                  .total_current_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                  >
                                    <div className={`${style.addBtn}`}>
                                      {getItemLocal("family") ? (
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-gold-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Gold Assets</span>
                                        </Link>
                                      ) : (
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-gold-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Gold Assets</span>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable ${style.goldTbl} ${style["bold-table"]} ${style.headerTable} mb-0`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">
                                              Name of Asset (category)
                                            </td>
                                            <td scope="col">Invested Value</td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">Returns</td>
                                            <td scope="col">Absolute Return</td>
                                            <td scope="col">&nbsp;</td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.goldTbl} ${style.dataTable} mb-0`}
                            >
                              <tbody>
                                {otherInvestmentData?.gold_data?.gold_details ? (
                                  otherInvestmentData.gold_data.gold_details.map(
                                    (v) => (
                                      <tr key={v.id}>
                                        <td
                                          scope="row"
                                          data-label="Name of Asset (category)"
                                        >
                                          <AssetName
                                            title={
                                              v.asset_name ? v.asset_name : "-"
                                            }
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "/assets/img/assets-liabilities/assets_gold.svg"
                                            }
                                          />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Invested Value"
                                        >
                                          <strong>
                                            {v.inv_val != 0
                                              ? indianRupeeFormat(v.inv_val * 1)
                                              : "-"}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Current Value"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.cr_val != 0
                                              ? indianRupeeFormat(v.cr_val * 1)
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Returns"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v.rtn * 1 < 0
                                              ? "red"
                                              : v.rtn * 1 === 0 || v.rtn === "-"
                                                ? ""
                                                : "green"
                                              }`}
                                          >
                                            {v.rtn * 1 != 0
                                              ? indianRupeeFormat(v.rtn * 1)
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Absolute Return"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v.abs_rtn * 1 < 0
                                              ? "red"
                                              : v.abs_rtn * 1 === 0 ||
                                                v.abs_rtn === "-"
                                                ? ""
                                                : "green"
                                              }`}
                                          >
                                            {v.abs_rtn * 1 != 0
                                              ? v.abs_rtn + " %"
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td className={""}>
                                          <div
                                            className={style.actionBtnsContainer}
                                          >
                                            {
                                              process.env.REACT_APP_MODE === 'stg' && <><i
                                              className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                            ></i>
                                            {getItemLocal("family") ? (
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              />
                                            ) : (
                                              <Link
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-gold-asset/" +
                                                  btoa(v.id)
                                                }
                                              >
                                                <i
                                                  className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                ></i>
                                              </Link>
                                            )}</>
                                            }

                                            {getItemLocal("family") ? (
                                              <i
                                                class={`disabled fa fa-trash ${style.trash}`}
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <i
                                                class={`fa fa-trash ${style.trash}`}
                                                onClick={() =>
                                                  Swal.fire({
                                                    title:
                                                      "Are you sure you want to delete?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      deleteInsuranceItem(
                                                        v.id,
                                                        "gold",
                                                        v.fp_log_id
                                                          ? v.fp_log_id
                                                          : ""
                                                      );
                                                    }
                                                  })
                                                }
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                                                                </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems like we don't have your
                                        Gold Assets Data to display
                                      </p>
                                      {
                                        process.env.REACT_APP_MODE === 'stg' && <p className="text-center fw-bold">
                                        You can add your existing Gold Assets
                                      </p>
  }

                                      <div className="text-center">
                                        {
                                          process.env.REACT_APP_MODE === 'stg' && <> {getItemLocal("family") ? (
                                          <Link
                                            className="disabled anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-gold-asset"
                                            }
                                          >
                                            Add Gold Assets
                                          </Link>
                                        ) : (
                                          <Link
                                            className="anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-gold-asset"
                                            }
                                          >
                                            Add Gold Assets
                                          </Link>
                                        )}</>
                                        }

                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/gold-icon.png"
                                          }
                                          style={{ width: 400 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}
                        {/* liquid section */}
                        {/* <div
                          className={`insideTabContent tbl-09 px-2 px-md-4 ${selectedTab == 8 ? "" : "d-none"
                            }`}
                        >
                          {"liquid_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.liquid_data).length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-9">
                                    <div className="d-block">
                                      <div className="cntResults">
                                        <div className={style.ecntRItems}>
                                          <div className={style.borderSpace}>
                                            Total Liquid Assets
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData?.liquid_data
                                              ?.no_of_assets != undefined
                                              ? otherInvestmentData.liquid_data
                                                .no_of_assets
                                              : "-"}
                                          </div>
                                        </div>
                                        <div className={style.ecntRItems}>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Invested Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                                                                      {"-"}
                                          </div>
                                        </div>
                                        <div
                                          className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                        >
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.liquid_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData?.liquid_data
                                                  ?.total_current_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                  >
                                    <div className={`${style.addBtn}`}>
                                      {getItemLocal("family") ? (
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-liquid-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Liquid Assets</span>
                                        </Link>
                                      ) : (
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-liquid-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Liquid Assets</span>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable ${style["bold-table"]} ${style["liquid-table"]} mb-0 ${style.headerTable}`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">
                                              Name of Asset (Category)
                                            </td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">&nbsp;</td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="  ">
                            <Table
                              className={`ptTable fixedTable ${style["liquid-table"]} mb-0 ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.liquid_data
                                  ?.liquid_details ? (
                                  otherInvestmentData.liquid_data.liquid_details.map(
                                    (v) => (
                                      <tr key={v.id}>
                                        <td
                                          scope="row"
                                          data-label="Type/Name Of Property"
                                        >
                                          <AssetName
                                            title={
                                              v.asset_name ? v.asset_name : "-"
                                            }
                                            icon={
                                              process.env
                                                .REACT_APP_STATIC_URL_PYTHON +
                                              "/assets/img/assets-liabilities/assets_liquid.svg"
                                            }
                                          />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="Purchased Value"
                                        >
                                          <span className={`xrr-returns`}>
                                            {v.cr_val
                                              ? indianRupeeFormat(v.cr_val * 1)
                                              : "-"}
                                          </span>
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "2rem",
                                            }}
                                          >
                                            {
                                              process.env.REACT_APP_MODE === 'stg' && <> <i
                                              className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                            ></i>
                                            {getItemLocal("family") ? (
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              />
                                            ) : (
                                              <Link
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-liquid-asset"
                                                }
                                              >
                                                <i
                                                  className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                ></i>
                                              </Link>
                                            )}</>
                                            }


                                            {getItemLocal("family") ? (
                                              <i
                                                class={`disabled fa fa-trash ${style.trash}`}
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <i
                                                class={`fa fa-trash ${style.trash}`}
                                                onClick={() =>
                                                  Swal.fire({
                                                    title:
                                                      "Are you sure you want to delete?",
                                                    showConfirmButton: true,
                                                    showCancelButton: true,
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      deleteInsuranceItem(
                                                        v.id,
                                                        "liquid",
                                                        v.fp_log_id
                                                          ? v.fp_log_id
                                                          : ""
                                                      );
                                                    }
                                                  })
                                                }
                                                aria-hidden="true"
                                              />
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems we don't have your
                                        Liquid Assets Data to display
                                      </p>
                                      {
                                        process.env.REACT_APP_MODE === 'stg' && <p className="text-center fw-bold">
                                        You can add your existing Liquid Assets
                                      </p>
  }

                                      <div className="text-center">
                                        {
                                          process.env.REACT_APP_MODE === 'stg' && <>{getItemLocal("family") ? (
                                          <Link
                                            className="disabled anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-liquid-asset"
                                            }
                                          >
                                            Add Liquid Assets
                                          </Link>
                                        ) : (
                                          <Link
                                            className="anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-liquid-asset"
                                            }
                                          >
                                            Add Liquid Assets
                                          </Link>
                                        )}</>
                                        }

                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/liquid_image.svg"
                                          }
                                          alt="Liquid"
                                          style={{ width: 400 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}
                        {/* other section */}
                        {/* <div
                          className={`insideTabContent tbl-10 px-2 px-md-4 ${selectedTab == 9 ? "" : "d-none"
                            }`}
                        >
                          {"other_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.other_data).length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-9">
                                    <div className="d-block">
                                      <div className="cntResults">
                                        <div className={style.ecntRItems}>
                                          <div className={style.borderSpace}>
                                            Total Other Assets
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData?.other_data
                                              ?.no_of_assets != undefined
                                              ? otherInvestmentData.other_data
                                                .no_of_assets
                                              : "-"}
                                          </div>
                                        </div>
                                        <div className={style.ecntRItems}>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Invested Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                                                                      {"-"}
                                          </div>
                                        </div>
                                        <div
                                          className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                        >
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.other_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.other_data
                                                  .total_current_rate * 1
                                              )
                                              : "-"}
                                                                                    </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable ${style["bold-table"]} ${style.otherInvTbl} ${style.headerTable} mb-0`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">
                                              Name of Asset (category)
                                            </td>
                                            <td scope="col">Invested Value</td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">Returns</td>
                                            <td scope="col">Absolute Return</td>
                                            <td scope="col">&nbsp;</td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="  ">
                            <Table
                              responsive
                              className={`ptTable fixedTable ${style.otherInvTbl} ${style.dataTable} mb-0`}
                            >
                              <tbody>
                                {otherInvestmentData?.other_data
                                  ?.other_details ? (
                                  otherInvestmentData.other_data.other_details.map(
                                    (v) => (
                                      <tr key={v.id}>
                                        <td scope="row" data-label="title">
                                          <AssetName
                                            title={
                                              v.asset_name ? v.asset_name : "-"
                                            }
                                          />
                                        </td>
                                        <td scope="row" data-label="invested val">
                                          <strong>
                                                                                      {"-"}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="current val"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v.cr_val != 0
                                              ? indianRupeeFormat(v.cr_val * 1)
                                              : "-"}
                                                                                    </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="returns"
                                          className=""
                                        >
                                          <strong className={`xrr-returns}`}>
                                            {"-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="abs return"
                                          className=""
                                        >
                                          <strong className={`xrr-returns}`}>
                                            {"-"}
                                          </strong>
                                        </td>
                                        <td className={style.newwidth}>
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                Swal.fire({
                                                  title:
                                                    "Are you sure you want to delete?",
                                                  showConfirmButton: true,
                                                  showCancelButton: true,
                                                  confirmButtonText: "Yes",
                                                  cancelButtonText: "No",
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    deleteInsuranceItem(
                                                      v.id,
                                                      "others",
                                                      v.fp_log_id
                                                        ? v.fp_log_id
                                                        : ""
                                                    );
                                                  }
                                                })
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                                                                </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems we don't have your
                                        Others Assets Data to display
                                      </p>
                                      {
                                        process.env.REACT_APP_MODE === 'stg' && <p className="text-center fw-bold">
                                        You can add your existing Others Assets
                                      </p>
  }

                                      <div className="text-center">
                                        {
                                          process.env.REACT_APP_MODE === 'stg' && <>{getItemLocal("family") ? (
                                          <Link
                                            className="disabled anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-gold-asset"
                                            }
                                          >
                                            Add Others Assets
                                          </Link>
                                        ) : (
                                          <Link
                                            className="anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-gold-asset"
                                            }
                                          >
                                            Add Others Assets
                                          </Link>
                                        )}</>
                                        }

                                        <img
                                          className="pt-4"
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "/media/others-icon.png"
                                          }
                                          style={{ width: 300 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}
                        {/* stocks section */}
                        {/* <div
                          className={`insideTabContent tbl-11 px-2 px-md-4 ${selectedTab == 10 ? "" : "d-none"
                            }`}
                        >
                          {"stocks_data" in otherInvestmentData &&
                            Boolean(
                              Object.keys(otherInvestmentData.stocks_data).length
                            ) && (
                              <>
                                <div className="row pb-3">
                                  <div className="col-12 col-md-9">
                                    <div className="d-block">
                                      <div className="stocksTabInfo">
                                        <div>
                                          <div className={style.borderSpace}>
                                            Total Stocks
                                          </div>
                                          <div
                                            className={`borderSpace borderText pointer`}
                                          >
                                            {otherInvestmentData?.stocks_data
                                              ?.no_of_stocks != undefined
                                              ? otherInvestmentData.stocks_data
                                                .no_of_stocks
                                              : "-"}
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Invested Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.stocks_data
                                              ?.total_purchase_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.stocks_data
                                                  .total_purchase_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Current Value
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                          >
                                            {otherInvestmentData?.stocks_data
                                              ?.total_current_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.stocks_data
                                                  .total_current_rate * 1
                                              )
                                              : "-"}
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Today Gain/Loss
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                            style={
                                              otherInvestmentData?.stocks_data
                                                ?.overall_oneday_perc &&
                                                otherInvestmentData.stocks_data
                                                  .overall_oneday_perc < 0
                                                ? { color: "red" }
                                                : { color: "green" }
                                            }
                                          >
                                            {otherInvestmentData?.stocks_data
                                              ?.overall_oneday_val
                                              ? indianRupeeFormat(
                                                otherInvestmentData?.stocks_data
                                                  ?.overall_oneday_val * 1
                                              )
                                              : "-"}
                                            {otherInvestmentData?.stocks_data
                                              ?.overall_oneday_perc
                                              ? " (" +
                                              otherInvestmentData?.stocks_data
                                                ?.overall_oneday_perc +
                                              "%)"
                                              : "-"}
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={`${style.extraSpace} ${style.borderSpace}`}
                                          >
                                            Total Gain/Loss
                                          </div>
                                          <div
                                            className={`borderSpace borderText`}
                                            style={
                                              otherInvestmentData?.stocks_data
                                                ?.total_purchase_rate &&
                                                otherInvestmentData?.stocks_data
                                                  .total_current_rate &&
                                                otherInvestmentData.stocks_data
                                                  .total_current_rate -
                                                otherInvestmentData.stocks_data
                                                  .total_purchase_rate <
                                                0
                                                ? { color: "red" }
                                                : { color: "green" }
                                            }
                                          >
                                            {otherInvestmentData?.stocks_data
                                              ?.total_current_rate &&
                                              otherInvestmentData?.stocks_data
                                                .total_purchase_rate
                                              ? indianRupeeFormat(
                                                otherInvestmentData.stocks_data
                                                  .total_current_rate *
                                                1 -
                                                otherInvestmentData
                                                  .stocks_data
                                                  .total_purchase_rate *
                                                1
                                              )
                                              : "-"}
                                            {otherInvestmentData?.stocks_data
                                              ?.overall_returns &&
                                              otherInvestmentData.stocks_data
                                                .overall_returns != ""
                                              ? " (" +
                                              otherInvestmentData.stocks_data
                                                .overall_returns +
                                              "%)"
                                              : "-"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`col-12 col-md-3 ${style.addBtnContainer} ${style.wrapBtns} `}
                                  >
                                    <span>
                                      <div
                                        className={`${style.addBtn}`}
                                        style={{ paddingBottom: "0px" }}
                                      >
                                        {getItemLocal("family") ? (
                                          <div
                                            className="disabled resultOptionsBtn"
                                            style={{ padding: "5px 10px" }}
                                          >
                                            <i className="fa-solid fa-link"></i>
                                            <span>Fetch Your Holdings</span>
                                          </div>
                                        ) : (
                                          <div
                                            className="resultOptionsBtn"
                                            style={{ padding: "5px 10px" }}
                                          >
                                            <i className="fa-solid fa-link"></i>
                                            <span>Fetch Your Holdings</span>
                                          </div>
                                        )}
                                      </div>
                                      <small>
                                        Last Updated on 20th April 2023
                                      </small>
                                    </span>

                                    <div
                                      className={`${style.addBtn}`}
                                      style={{ marginTop: "-5px" }}
                                    >
                                      <FintooDropdown
                                        onChange={(v) => stockSort(v)}
                                        sortOptions={[
                                          "Invested Value",
                                          "Current Value",
                                          "Today Return",
                                          "Total Return",
                                        ]}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="fixedHeaders">
                                  <div className="table-responsive">
                                    <div className="ptTableBx">
                                      <Table
                                        className={`ptTable ${style.stockTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                      >
                                        <thead>
                                          <tr>
                                            <td scope="col">Stock Name</td>
                                            <td scope="col">Invested Value</td>
                                            <td scope="col">Current Value</td>
                                            <td scope="col">No. of Shares</td>
                                            <td scope="col">Today Returns</td>
                                            <td scope="col">Total Returns</td>
                                            <td scope="col">&nbsp;</td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="">
                            <Table
                              responsive
                              className={`mb-0 ptTable fixedTable ${style.stockTbl} ${style.dataTable}`}
                            >
                              <tbody>
                                {otherInvestmentData?.stocks_data
                                  ?.stocks_details ? (
                                  otherInvestmentData.stocks_data.stocks_details.map(
                                    (v) => (
                                      <tr key={v.id}>
                                        <td scope="row" data-label="title">
                                          <AssetName
                                            title={
                                              <>
                                                <p className="mb-0">
                                                  <strong>
                                                    {v.stock_name
                                                      ? v.stock_name
                                                      : "-"}
                                                  </strong>
                                                </p>
                                                <p className="mb-0">
                                                  {v.sector_name +
                                                    " - " +
                                                    v.industry_name}{" "}
                                                  |{" "}
                                                  {v.prev_day_val != 0 ||
                                                    v.prev_day_val != ""
                                                    ? indianRupeeFormat(
                                                      v.prev_day_val * 1
                                                    )
                                                    : "-"}
                                                </p>
                                              </>
                                            }
                                          />
                                        </td>
                                        <td scope="row" data-label="invested val">
                                          <strong>
                                            {v?.inv_val
                                              ? v.inv_val != 0
                                                ? indianRupeeFormat(v.inv_val * 1)
                                                : "-"
                                              : "-"}
                                          </strong>
                                          <br />
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="current val"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v?.cr_val
                                              ? v.cr_val != 0
                                                ? indianRupeeFormat(v.cr_val * 1)
                                                : "-"
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="current val"
                                          className=""
                                        >
                                          <strong className={`xrr-returns`}>
                                            {v?.asset_units
                                              ? v.asset_units != 0
                                                ? Math.round(
                                                  (v.asset_units * 100) / 100
                                                )
                                                : "-"
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="returns"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v?.day_change_perc
                                              ? v?.day_change_perc * 1 < 0
                                                ? "red"
                                                : v.day_change_perc * 1 === 0
                                                  ? ""
                                                  : "green"
                                              : ""
                                              }`}
                                          >
                                            {v?.today_rtn
                                              ? v.today_rtn != 0
                                                ? indianRupeeFormat(v.today_rtn)
                                                : "-"
                                              : "-"}
                                            {v?.day_change_perc
                                              ? " (" + v.day_change_perc + "%) "
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td
                                          scope="row"
                                          data-label="abs return"
                                          className=""
                                        >
                                          <strong
                                            className={`xrr-returns ${v?.asset_ror
                                              ? v.asset_ror * 1 < 0
                                                ? "red"
                                                : v.asset_ror * 1 === 0 ||
                                                  v.asset_ror === "-"
                                                  ? ""
                                                  : "green"
                                              : ""
                                              }`}
                                          >
                                            {v?.cr_val && v?.inv_val
                                              ? v.cr_val != 0
                                                ? indianRupeeFormat(
                                                  v.cr_val - v.inv_val
                                                )
                                                : "-"
                                              : "-"}
                                          </strong>
                                          <strong
                                            className={`xrr-returns ${v?.gain_perc
                                              ? v.gain_perc * 1 < 0
                                                ? "red"
                                                : v.gain_perc * 1 === 0 ||
                                                  v.gain_perc === "-"
                                                  ? ""
                                                  : "green"
                                              : ""
                                              }`}
                                          >
                                            {v?.gain_perc
                                              ? " (" + v.gain_perc + "%) "
                                              : "-"}
                                          </strong>
                                        </td>
                                        <td>
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                Swal.fire({
                                                  title:
                                                    "Are you sure you want to delete?",
                                                  showConfirmButton: true,
                                                  showCancelButton: true,
                                                  confirmButtonText: "Yes",
                                                  cancelButtonText: "No",
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    deleteInsuranceItem(
                                                      v.id,
                                                      "stocks",
                                                      v.fp_log_id
                                                        ? v.fp_log_id
                                                        : ""
                                                    );
                                                  }
                                                })
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                                                                </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <>
                                    <div>
                                      <br />
                                      <p className="text-center fw-bold mb-0">
                                        Currently it seems you don't have any
                                        Holdings to display your Stocks Portfolio
                                      </p>
                                      <br />
                                      <div className="text-center">
                                        {
                                          process.env.REACT_APP_MODE === 'stg' && <div className="row">
                                          <Link
                                            className="anchor-primary"
                                            to={"#"}
                                          >
                                            Buy Stocks
                                          </Link>
                                          {getItemLocal("family") ? (
                                            <Link
                                              className="disabled anchor-primary"
                                              to={"#"}
                                            >
                                              Fetch Your Holdings
                                            </Link>
                                          ) : (
                                            <Link
                                              className="anchor-primary"
                                              to={"#"}
                                            >
                                              Fetch Your Holdings
                                            </Link>
                                          )}
                                          <Link
                                            className="anchor-primary"
                                            to={"#"}
                                          >
                                            Open Account
                                          </Link>
                                        </div>
  }

                                        <img
                                          src={
                                            process.env.REACT_APP_STATIC_URL +
                                            "media/DMF/investment.png"
                                          }
                                          className="pt-4"
                                          alt={"Start Investing"}
                                          style={{ width: 300 }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </>
        )}
        <FilterSidePanel
          isOpen={isFilterPanelActive}
          togglePanel={setIsFilterPanelActive}
          mainData={mainData}
          setMainData={setMainData}
          mfListDataCopy={mfListDataCopy}
          fetchFundsData={fetchFundsData}
          resetFilterTriggerState={resetFilterTriggerState}
          setResetFilterTriggerState={setResetFilterTriggerState}
        />
        <SelectMemberModal
          isOpen={isOpenPopup}
          onClose={() => {
            setIsOpenPopup(false);
          }}
        />
      </PortfolioLayout>
    </MainLayout>
  );
};

const BulletPoint = ({ heading, text }) => {
  return (
    <div className="d-flex py-3">
      <img
        className={style["bullet-item-img"]}
        src={getPublicMediaURL("/static/media/icons/check_01.svg")}
      />
      <div className={`ps-2`}>
        <div className={style["bullet-item-heading"]}>{heading}</div>
        <div>{text}</div>
      </div>
    </div>
  );
};

const ActionButton = ({ onClick, label, disabled = false }) => {
  return (
    <button
      className={style["bullet-item-ActionButton"]}
      disabled={disabled ? "disabled" : ""}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default PortfolioDashboard;
