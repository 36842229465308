import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../HTML/FintooBackButton";
import commonEncode from "../../commonEncode";
import * as constClass from "../../constants";
import axios from "axios";
import moment from "moment";
import {
  fetchEncryptData,
  apiCall,
  getUserId,
  getItemLocal,
  getCurrentUserDetails,
} from "../../common_utilities";
import PaymentSuccess from "../Pages/ErrosPages/PaymentSuccess";
import { useNavigate } from "react-router-dom";
import SubmitButton from "./SubmitButton";
import { useDispatch } from "react-redux";
import FintooLoader from "../FintooLoader";

const PortfolioOtpModal = (props) => {
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [useremail, setuseremail] = useState("");
  const [usermobile, setusermobile] = useState("");
  const [userdata, Setuserdata] = useState("");
  const [generateotp, setGeneratedSmsOTP] = useState("");
  const [generatedemailotp, setGeneratedEmailOTP] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [validOtp, setValidOtp] = useState(true);
  const [primaryBankId, setPrimaryBankId] = useState("");
  const [switchfunds, setSwitchFunds] = useState(false);
  const [deletecart, setDeleteCart] = useState("");
  const [nomineeflag, setnomineeflag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startStp, setStartStp] = useState("");
  const [sipamount, setamount] = useState("");
  const [sipfund, setfundname] = useState("");
  const navigate = useNavigate();
  const [stopSIPButtonDisable, setStopSIPButtonDisable] = useState(false);
  const dispatch = useDispatch();

  // var cartId = localStorage.getItem("cartId");

  useEffect(() => {
    onLoadInIt();
    fetchMail();
    // fetchSms();
  }, [useremail, userdata]);

  useEffect(() => {
    if (usermobile) {
      fetchSms();
    }
  }, [usermobile]);

  const randomOTP = useRef(Math.floor(Math.random() * 90000) + 10000);

  function handleClick() {
    randomOTP.current = Math.floor(Math.random() * 90000) + 10000;
    fetchSms(randomOTP.current);
    fetchMail(randomOTP.current);
  }

  const switchFund = async () => {
    setStopSIPButtonDisable(true);
    var trans_code = "NEW";
    var trans_id = "" + props.value[1].current.transaction_id;
    var res = await apiCall(constClass.DMF_SWITCH_FUND_API_URL, {
      transaction_id: trans_id,
      trans_code: trans_code,
      is_direct: constClass.IS_DIRECT,
    });
    setSwitchFunds(res.data);
    if (res.error_code * 1 === 100) {
      await deleteCart();
      setStopSIPButtonDisable(false);
      navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentSucess?a=Switch");
    } else {
      setStopSIPButtonDisable(false);
      navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentFailed?a=Switch");
    }
  };

  const startSwp = async () => {
    setStopSIPButtonDisable(true);
    var trans_id = props.transaction_id.transaction_id;
    var res = await apiCall(constClass.DMF_START_SWP_API_URL, {
      trxn_id: trans_id.toString(),
      is_direct: constClass.IS_DIRECT,
    });
    setStopSIPButtonDisable(false);
    if (res.error_code * 1 === 100) {
      navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentSucess?a=SWP");
    } else {
      navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentFailed?a=swp");
    }
  };

  const deleteCart = async () => {
    
    try {
      var cart_id_from = "" + props.value[0].cartIdRef.cart_id_from;
      var cart_id_to = "" + props.value[0].cartIdRef.cart_id_to;
      var payload = {
        method: "post",
        url: constClass.DMF_DELETE_SWP_STP_FROM_CART,
        data: {
          from_data: [
            {
              cart_id: cart_id_from,
            },
          ],
          to_data: [
            {
              cart_id: cart_id_to,
            },
          ],
        },
      };
      var res = await fetchEncryptData(payload);
      setDeleteCart(res.data);
    } catch (e) {}
  };

  const updatenominee = async () => {
    try {
      var res = await apiCall(constClass.DMF_UPDATENOMINEE_API_URL, {
        user_id: getUserId(),
        is_direct: constClass.IS_DIRECT,
        is_authenticated: "1",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOtpChange = (e) => {
    if (e.target.value.length > 5) {
      setOtpInput("");
      setValidOtp(false);
    } else {
      setOtpInput(e.target.value);
      setValidOtp(true);
    }
  };

  const submitOtp = () => {
    if (generatedemailotp != otpInput) {
      setValidOtp(false);
    } else {
      localStorage.removeItem("switch_from");
      localStorage.removeItem('switch_to')
      setValidOtp(true);
      if (props.label == "Redeem Fund") {
        normalOrderEntry();
      }
      if (props.label == "Switch Fund") {
        switchFund();
      }
      if (props.label == "Stop SIP") {
        // stopsipmail("fail");
        cancelSip();
      }
      if (props.label == "Confirm SWP") {
        startSwp();
      }
      if (props.label == "Confirm STP") {
        startSTP();
      }
      if (nomineeflag == null || nomineeflag == "") {
        // console.log("call api")
        updatenominee();
      }
    }
  };
  const startSTP = async () => {
    setStopSIPButtonDisable(true);
    try {
      setIsLoading(true);
      let config = {
        method: "post",
        url: constClass.DMF_BASE_URL + "api/bse/stpregistration",
        data: {
          transaction_ids: props.stpTransactionId,
          is_direct: constClass.IS_DIRECT,
        }
      };

      var res2 = await fetchEncryptData(config);
      setIsLoading(false);
      console.log("res2L  ::  ", res2);
      setStartStp(res2.data);
      setStopSIPButtonDisable(false);
      if (res2.error_code * 1 === 100) {
        navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentSucess?a=stp");
        deleteCart();
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: res2.message, type: "success" },
        });
      } else {
        navigate(process.env.PUBLIC_URL + "/mutual-fund/PaymentFailed?a=stp");
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: res2.message, type: "error" },
        });
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      console.log("Start STP::: ", e);
    }
  };
  // console.log("startStp",startStp)

  const normalOrderEntry = async () => {
    try {
      setStopSIPButtonDisable(true);
      let x = props.transaction_id.current.transaction_id.toString();
      let allRedeemKey = props.allRedeemKey;
      var payload = {
        method: "post",
        url: constClass.DMF_NORMAL_ORDER_ENTRY_API_URL,
        data: {
          user_id: getUserId(),
          transaction_id: x,
          trans_code: "NEW",
          is_direct: constClass.IS_DIRECT,
          allRedeemKey: allRedeemKey,
        },
      };
      var res = await fetchEncryptData(payload);
      await deleteCartAPI();
      setStopSIPButtonDisable(false);
      if (res.error_code * 1 === 100) {
        navigate(
          process.env.PUBLIC_URL + "/mutual-fund/PaymentSucess?a=Redeem"
        );
      } else {
        navigate(
          process.env.PUBLIC_URL + "/mutual-fund/PaymentFailed?a=Redeem"
        );
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      console.log("catch :", e);
    }
  };

  const cancelSip = async () => {
    setStopSIPButtonDisable(true);
    try {
      let transactionId = "";
      for (const x of props?.detailedMfPotfolio?.sip_stp_swp_data?.moreSIP) {
        if (Boolean(x.checked)) {
          transactionId = "" + x.transaction_id;
          break;
        }
      }
      if (!transactionId) {
        throw "Unable to get transactionId";
      }
      // return;
      var payload = {
        method: "post",
        url: constClass.DMF_XSIP_ORDER_ENTRY_API_URL,
        data: {
          user_id: getUserId(),
          trxn_id: transactionId,
          trans_code: "CXL",
          is_direct: constClass.IS_DIRECT,
        },
      };
      if (props?.reason) {
        payload.data.reason_code = "" + props?.reason?.id;
        payload.data.reason_text = props?.reason?.text;
      }
      var res = await fetchEncryptData(payload);
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 5000);
      });

      setStopSIPButtonDisable(false);
      if (res.error_code * 1 === 100) {
        stopsipmail("sucess");
        navigate(
          process.env.PUBLIC_URL + "/mutual-fund/PaymentSucess?a=StopSIP"
        );
      } else {
        stopsipmail("fail");
        navigate(
          process.env.PUBLIC_URL + "/mutual-fund/PaymentFailed?a=StopSIP"
        );
      }
    } catch (e) {
      setStopSIPButtonDisable(false);
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Something went wrong.",
          type: "error",
        },
      });
      console.log("catch :", e);
    }
  };

  const stopsipmail = async (v) => {
    try {
      let user_data = await getCurrentUserDetails();
      console.log(sipamount);
      var subject = "";
      var template = "";

      if (v === "sucess") {
        subject = "Confirmation: Successful Termination of SIP Process";
        template = "stop_sip_rmf.html";
      }
      if (v === "fail") {
        subject =
          "Notification Regarding Failed Stop SIP Request on BSE Platform";
        template = "stop_sip_fail_rmf.html";
      }
      var urlmail = {
        userdata: {
          to: user_data.email,
        },
        subject: subject,
        template: template,
        contextvar: {
          name: user_data.name,
          platform: "FIntooinvest",
          amount: sipamount,
          fundname: sipfund,
          base_url: constClass.DMF_URL,
          date: moment(new Date()).format("DD/MM/YYYY"),
        },
      };

      // console.log(urlmail.contextvar,"!!11111")
      let config = {
        method: "post",
        url: constClass.RMF_EMAIL_API_URL,
        data: urlmail,
      };

      var res = await fetchEncryptData(config);
      console.log(res, "response mail");
    } catch (e) {
      console.log("------->", e);
    }
  };

  const deleteCartAPI = async () => {
    var cart_id = props.value[2].current.cart_id.toString();
    var payload = {
      method: "post",
      url: constClass.DMF_CART_DELETE_API_URL,
      data: {
        cart_id: cart_id.toString(),
      },
    };
    var res = await fetchEncryptData(payload);
  };

  const fetchSms = async (v) => {
    console.log(v,props.value,keyvalue, "shree78")
    var sms_api_id = "fintoo_otp";
    var otp = v?v:randomOTP.current;
    var fromfund = localStorage.getItem("switch_from");
    var switchto = localStorage.getItem("switch_to");
    var amount = getItemLocal("red_amount");

    if (props.value[3] === "stpkey") {
      var msg = `Dear ${
        props.value[2].fund_details[0].name
      }, your OTP to confirm STP of ${
        props.value[1].from_data.cart_amount
      } from ${props.value[1].from_data.start_date} to ${
        props.value[1].from_data.end_date
      } on ${moment().toDate()} is ${otp}. Do not share this OTP with anyone. It is valid for a single use only. -Fintooinvest`;
      var whatsapptext = `Dear ${
        props.value[2].fund_details[0].name
      }, your OTP to confirm STP of ${
        props.value[1].from_data.cart_amount
      } from ${props.value[1].from_data.start_date} to ${
        props.value[1].from_data.end_date
      } on ${moment().toDate()} is ${otp}. Do not share this OTP with anyone. It is valid for a single use only. -Fintooinvest`;
      sms_api_id = "rmf_stp_otp";
    } else {
      var msg =
        "Greetings from Fintooinvest! Your OTP verification code is " + otp;
      var whatsapptext =
        "Greetings from Fintooinvest! Your OTP verification code is : " + otp;
    }
    var urlsms = {
      mobile: usermobile,
      msg: msg,
      sms_api_id: sms_api_id,
      whatsapptext: whatsapptext,
    };
    var keyvalue = "";
    if (props.label == "Redeem Fund") {
      keyvalue = "redeem_rmf";
    } else if (props.label == "Switch Fund") {
      keyvalue = "switch";
    } else if (props.label == "Stop SIP") {
      keyvalue = "stop_sip";
    } else if (props.label == "Confirm SWP") {
      keyvalue = "swp";
    } else if (props.label == "Confirm STP") {
      keyvalue = "stp";
    } else if (props.label == "Stop SWP") {
      keyvalue = "stop_swp";
    }

    // if(props.props == 'Redeem Fund' )
    // {
    //   var urlsms = {
    //     mobile: usermobile,
    //     msg: msg,
    //     sms_api_id: sms_api_id,
    //     whatsapptext: whatsapptext,
    //   }
    // }

    var from_fund = "";
    var amount_value = "";
    if (Number(props.value[0].inv) > 0) {
      amount_value = props.value[0].inv;
    }

    // debugger
    if (keyvalue == "switch" || keyvalue == "swp" || keyvalue == "redeem_rmf") {
      amount_value = amount;
      // debugger
      from_fund = fromfund;
      if (keyvalue == "redeem_rmf" && props.value[3]) {
        amount_value = "" + props.value[3];
      }
    } else if (keyvalue == "stp") {
      from_fund = props.value[0].scheme;
    } else if ((keyvalue == "stop_sip")) {
      amount_value = "" + amount;
    }

    let fund_name_value = props.value[0].scheme
      ? props.value[0].scheme.replaceAll("&", "and")
      : "";
    setfundname(fund_name_value);
    let from_fund_name = from_fund ? from_fund.replaceAll("&", "and") : "";
    let to_fund_name = switchto ? switchto.replaceAll("&", "and") : "";
    let from_f_name =
      from_fund_name.length <= 20
        ? from_fund_name
        : from_fund_name.slice(0, 20) + "...";
    let switch_to =
      to_fund_name.length <= 20
        ? to_fund_name
        : to_fund_name.slice(0, 20) + "...";
    fund_name_value =
      fund_name_value.length <= 20
        ? fund_name_value
        : fund_name_value.slice(0, 20) + "...";

    var urlsms = {
      mobile: usermobile,
      otp: otp,
      key: keyvalue,
      user_name: props.value[0].name,
      fund_name: fund_name_value,
      from_fund_name: from_f_name,
      to_fund_name: switch_to,
      is_direct: constClass.IS_DIRECT,
      amount: amount_value,
    };

    setamount(amount_value);
    // console.log(otp,">>>>>>>>>>>>>>")
    var config1 = {
      method: "post",
      url: constClass.RMF_SMS_API_URL,
      data: urlsms,
    };

    // console.log(otp,"<<<<<<")
    var res = await fetchEncryptData(config1);
  };

  const fetchMail = async (v) => {
    setOtpInput("");
    var otp = v?v:randomOTP.current;
    setGeneratedEmailOTP(otp);
    var urlmail = {
      userdata: {
        to: useremail,
      },
      subject: "Fintooinvest - Your one time password",
      template: "rmf_otp_message_template.html",
      contextvar: {
        otp: otp,
        emailmobile: "User",
        base_url: constClass.DMF_URL,
      },
    };
    console.log("OTP ", urlmail);

    var config = {
      method: "post",
      url: constClass.RMF_EMAIL_API_URL,
      data: urlmail,
    };

    var res = await fetchEncryptData(config);
  };

  const onLoadInIt = async () => {
    try {
      var userid = getUserId();
      var form_data_user = {
        user_id: "" + userid,
        is_direct: "" + constClass.IS_DIRECT,
      };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
      let user_data = responseobj.data;
      Setuserdata(user_data.pan);
      // setname(user_data.name)
      setnomineeflag(user_data.is_authenticated);
      setuseremail(user_data.email);
      setusermobile(user_data.mobile);
    } catch (e) {}
  };

  useEffect(() => {
    timer.current.counter = timer.current.default;
    startTimer();
  }, []);

  const startTimer = () => {
    timer.current.obj = setInterval(() => {
      if (timer.current.counter > 0) {
        timer.current.counter = timer.current.counter - 1;
        setCount(timer.current.counter);
      } else {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
      }
    }, 1000);
  };

  var props_data = props.value;

  return (
    <>
      <FintooLoader isLoading={isLoading} />
      {!isLoading && (
        <>
          <Modal.Header className="py-3">
            <FintooBackButton onClick={() => props.onBack()} />
            <div className="modal-title">Please Enter OTP</div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <div className="modal-whitepopup-box-item grey-color border-top-0 text-center">
                  <p>Sent to</p>
                  <p>{useremail}</p>
                  <p>{usermobile}</p>
                </div>

                <div className="modal-whitepopup-box-item grey-color">
                  <div className="px-md-4">
                    <div className="d-flex justify-content-center flx-otp-bx">
                      <div className="flx-item">
                        <div>Folio</div>
                        <div>
                          <strong>{props_data[0].folio_no}</strong>
                        </div>
                      </div>
                      <div className="flx-item">
                        <div>{props.label}</div>
                        <div>
                          <strong>{props_data[0].scheme}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center p-4">
                  <input
                    type="text"
                    maxLength={5}
                    minLength={5}
                    placeholder="Enter OTP here"
                    className="bottom-border-input w-50 text-center grey-color"
                    value={otpInput}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => handleOtpChange(e)}
                  />
                </div>

                <div className="text-center p-4 grey-color">
                  {count == 0 && (
                    <p
                      className="pointer blue-color"
                      onClick={() => {
                        startTimer();
                        handleClick();
                        setValidOtp(true);
                      }}
                    >
                      Resend OTP
                    </p>
                  )}
                  {count > 0 && (
                    <p>
                      Resend OTP in{" "}
                      <strong>
                        {moment().startOf("day").seconds(count).format("mm:ss")}
                      </strong>
                    </p>
                  )}
                  {validOtp ? <> </> : <p className="red-color">Invalid OTP</p>}
                </div>

                <SubmitButton
                  disabled={stopSIPButtonDisable}
                  title={"Submit"}
                  onClick={() => {
                    submitOtp();
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </>
      )}
    </>
  );
};
export default PortfolioOtpModal;
