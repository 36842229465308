import { useState, useEffect, useRef } from "react";
import PortfolioLayout from "../../../components/Layout/Portfolio";
import Styles from "./report.module.css";
import MutualFunds from "../../../components/PortfolioReport/MutualFunds";

const PortfolioReport = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <PortfolioLayout>
      <div className={`row ${Styles.PortfolioReportSection}`}>
        <div className="col-12 mt-5">
          <div className={Styles.insideTabBoxd}>
            <div className="d-flex pt-3">
              <div
                onClick={() => setSelectedTab(1)}
                className={`pointer ${Styles.tabBx} ${
                  selectedTab == 1 ? Styles.active : Styles.inactive
                }`}
              >
                <div className={`mb-0 ${Styles.tabText} align-items-center`}>
                  <div>Investment</div>
                </div>
              </div>
              <div className={`${Styles.VRline}`}></div>
              {/* <div
                onClick={() => setSelectedTab(2)}
                className={`pointer ps-5 ${Styles.tabBx} ${
                  selectedTab == 2 ? Styles.active : Styles.inactive
                }`}
              >
                <div className={`mb-0 ${Styles.tabText} align-items-center`}>
                  Advisory
                </div>
              </div> */}
              <div className={`${Styles.VRline}`}></div>
              {/* <div
                onClick={() => setSelectedTab(3)}
                className={`pointer ps-5 ${Styles.tabBx} ${
                  selectedTab == 3 ? Styles.active : Styles.inactive
                }`}
              >
                <div className={`mb-0 ${Styles.tabText} align-items-center`}>
                  Tax
                </div>
              </div> */}
              <div className={`${Styles.VRline}`}></div>
            </div>
          </div>
          <div className={`${Styles.TabSection}`}>
            <div className={selectedTab == 1 ? "d-block" : "d-none"}>
              <MutualFunds />
            </div>
            <div className={selectedTab == 4 ? "d-block" : "d-none"}>
              {/* <NewReportUI /> */}
            </div>
            <div className={selectedTab == 2 ? "d-block" : "d-none"}>
              {/* Advisory */}
            </div>
            <div className={selectedTab == 3 ? "d-block" : "d-none"}>
              {/* Tax */}
            </div>
          </div>
        </div>
      </div>
    </PortfolioLayout>
  );
};

export default PortfolioReport;
